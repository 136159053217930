import {
    chartColors,
    colorsGlobal,
    customColors,
    desklyColors,
    primitives,
} from '../../shared/mixins/colors';

export default {
    methods: {
        /**
         *
         * @param {AccountForm | false} whitelabelInformation
         * @returns void
         */

        whiteLabelCheck(whitelabelInformation = false) {
            if (whitelabelInformation?.favicon) {
                this.setFavIcon(whitelabelInformation.favicon);
            }

            if (whitelabelInformation?.applicationTitle) {
                this.setTitle(whitelabelInformation.applicationTitle);
            }

            if (whitelabelInformation?.customColors) {
                this.setColors(
                    whitelabelInformation.primaryColor,
                    whitelabelInformation.secondaryColor,
                    whitelabelInformation.hoverColor
                );
                return;
            }

            this.setDefaultColors();
        },
        setFavIcon(iconUrl) {
            const favicon = document.querySelector('[rel=icon]');
            favicon.href = iconUrl;
        },
        setTitle(title) {
            document.title = title;
        },
        setGlobalColors() {
            document.documentElement.style.setProperty('--black', colorsGlobal.black);
            document.documentElement.style.setProperty('--black-light', colorsGlobal.blackLight);
            document.documentElement.style.setProperty('--white', colorsGlobal.white);
            document.documentElement.style.setProperty('--gray', colorsGlobal.gray);
            document.documentElement.style.setProperty('--gray-light', colorsGlobal.grayLight);
            document.documentElement.style.setProperty('--warning', colorsGlobal.warning);
            document.documentElement.style.setProperty('--error', colorsGlobal.error);
            document.documentElement.style.setProperty('--error-dark', colorsGlobal.errorDark);
            document.documentElement.style.setProperty('--success', colorsGlobal.success);

            // chart colors
            document.documentElement.style.setProperty(
                '--resources-seat',
                chartColors.resources.seat
            );
            document.documentElement.style.setProperty(
                '--resources-green1',
                chartColors.resources.green1
            );
            document.documentElement.style.setProperty(
                '--resources-green2',
                chartColors.resources.green2
            );
            document.documentElement.style.setProperty(
                '--resources-green3',
                chartColors.resources.green3
            );
            document.documentElement.style.setProperty(
                '--resources-blue',
                chartColors.resources.blue
            );
            document.documentElement.style.setProperty(
                '--resources-zone',
                chartColors.resources.zone
            );
            document.documentElement.style.setProperty(
                '--resources-lila1',
                chartColors.resources.lila1
            );
            document.documentElement.style.setProperty(
                '--resources-lila2',
                chartColors.resources.lila2
            );
            document.documentElement.style.setProperty(
                '--resources-rosa1',
                chartColors.resources.rosa1
            );
            document.documentElement.style.setProperty(
                '--resources-rosa2',
                chartColors.resources.rosa2
            );
            document.documentElement.style.setProperty(
                '--resources-meetingRoom',
                chartColors.resources.meetingRoom
            );
            document.documentElement.style.setProperty(
                '--resources-red',
                chartColors.resources.red
            );
            document.documentElement.style.setProperty(
                '--resources-orange1',
                chartColors.resources.orange1
            );
            document.documentElement.style.setProperty(
                '--resources-orange2',
                chartColors.resources.orange2
            );
            document.documentElement.style.setProperty(
                '--resources-yellow',
                chartColors.resources.yellow
            );

            document.documentElement.style.setProperty(
                '--status-office',
                chartColors.status.office
            );
            document.documentElement.style.setProperty(
                '--status-mobile',
                chartColors.status.mobile
            );
            document.documentElement.style.setProperty(
                '--status-absent',
                chartColors.status.absent
            );

            // primitives
            setPaletteColorVariables('zinc', primitives.zinc);
            setPaletteColorVariables('blue', primitives.blue);
            setPaletteColorVariables('green', primitives.green);
            setPaletteColorVariables('yellow', primitives.yellow);
            setPaletteColorVariables('orange', primitives.orange);
            setPaletteColorVariables('red', primitives.red);
        },
        setColors(primary, secondary, hover) {
            //dynamic colors
            document.documentElement.style.setProperty('--primary', primary);
            document.documentElement.style.setProperty('--secondary', secondary);
            document.documentElement.style.setProperty('--hover', hover);
            document.documentElement.style.setProperty('--active', primary);
            document.documentElement.style.setProperty('--primary-inverse', colorsGlobal.white);

            setPaletteColorVariables('primary', primitives.zinc);

            //whitelabel colors
            document.documentElement.style.setProperty('--accent', customColors.accent);
            document.documentElement.style.setProperty('--accent-light', customColors.accentLight);
            document.documentElement.style.setProperty(
                '--accent-lighter',
                customColors.accentLighter
            );
            document.documentElement.style.setProperty('--background', customColors.background);
            document.documentElement.style.setProperty(
                '--background-light',
                customColors.backgroundLight
            );
            document.documentElement.style.setProperty(
                '--background-lighter',
                customColors.backgroundLighter
            );
        },
        setDefaultColors() {
            document.documentElement.style.setProperty('--primary', desklyColors.primary);
            document.documentElement.style.setProperty('--secondary', desklyColors.secondary);
            document.documentElement.style.setProperty('--hover', desklyColors.hover);
            document.documentElement.style.setProperty('--active', desklyColors.active);
            document.documentElement.style.setProperty('--primary-inverse', colorsGlobal.white);

            setPaletteColorVariables('primary', primitives.desklyPrimaryColorPalette);

            document.documentElement.style.setProperty('--accent', desklyColors.accent);
            document.documentElement.style.setProperty('--accent-light', desklyColors.accentLight);
            document.documentElement.style.setProperty(
                '--accent-lighter',
                desklyColors.accentLighter
            );
            document.documentElement.style.setProperty('--background', desklyColors.background);
            document.documentElement.style.setProperty(
                '--background-light',
                desklyColors.backgroundLight
            );
            document.documentElement.style.setProperty(
                '--background-lighter',
                desklyColors.backgroundLighter
            );
        },
    },
};

function setPaletteColorVariables(name, palette) {
    Object.keys(palette).forEach(number => {
        document.documentElement.style.setProperty(`--${name}-${number}`, palette[number]);
    });
}
