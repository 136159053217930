import sha256 from 'crypto-js/sha256';
import {generateRandomString} from '../authentication/OIDCHelper.js';
import Base64 from 'crypto-js/enc-base64';
import OIDCRepository from '../repositories/OIDCRepository.js';
import {OUTLOOK_PREFIX, outlookConfig} from './outlookConfig';
import {useToast} from '../../shared/components/toast/useToast.js';
import {googleConfig} from '../authentication/googleAuthConfig.js';
import OIDCClient from '../authentication/OIDCClient';
import {useOidcStore} from '../../stores/oidcStore';
import {useAuthenticationSettings} from '../../stores/authenticationSettingsStore';
import {GOOGLE_CALENDAR_PREFIX, getGoogleCalendarConfig} from './googleCalendarConfig';

export default class AuthClient {
    /**
     * @param {string} url
     * @param {URLSearchParams} parameters
     * @param {boolean} prompt
     */
    static redirect(url, parameters, prompt = true) {
        window.location.href = `${url}?${parameters}${prompt ? '' : '&prompt=none'}`;
    }

    /**
     * @param {boolean} prompt
     */
    static oidc(prompt = true) {
        const {authenticationSettings} = useAuthenticationSettings();

        const codeVerifier = generateRandomString(64);

        const codeChallenge = Base64.stringify(sha256(codeVerifier))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');

        // create state and nonce
        OIDCClient.createStateAndNonce();
        OIDCClient.safeCodeVerifier(codeVerifier);

        // redirect to oidc authorization url
        const oidcConfiguration = authenticationSettings.openIdConnectConfiguration;

        this.redirect(
            oidcConfiguration.authorizationEndpoint,
            new URLSearchParams({
                // required parameters
                audience: oidcConfiguration.audience,
                scope: oidcConfiguration.scopes,
                response_type: oidcConfiguration.responseType,
                client_id: oidcConfiguration.clientId,
                redirect_uri: oidcConfiguration.redirectUri,
                // recommended
                state: OIDCClient.oidcState(),
                code_challenge: codeChallenge,
                code_challenge_method: 'S256',
            }),
            prompt
        );
    }

    static googleLogin() {
        // create state and nonce
        OIDCClient.createStateAndNonce();

        const config = googleConfig();

        config.state = OIDCClient.oidcState();

        this.redirect('https://accounts.google.com/o/oauth2/v2/auth', new URLSearchParams(config));
    }

    /**
     * @param {string} url
     * @returns {Promise<boolean>}
     */
    static async processOidc(url) {
        const params = new URLSearchParams(url.split('?')[1]);
        const hostname = window.location.hostname;
        const subDomain = hostname.split('.').shift();

        if (params.get('error')) {
            useToast().error(`OIDC-Error: ${params.get('error_description')}`);
            return false;
        }

        const state = params.get('state');
        const code = params.get('code');

        if (!OIDCClient.compareState(state)) {
            throw new Error('State and nonce do not match');
        }

        const codeVerifier = OIDCClient.oidcCodeVerifier();

        const result = await OIDCRepository().fetchAccessToken(subDomain, code, codeVerifier);
        OIDCClient.clearStateAndNonce();
        useOidcStore().accessToken = result.access_token;

        return true;
    }

    static connectOutlook() {
        OIDCClient.createState(OUTLOOK_PREFIX);

        this.redirect(
            outlookConfig.url,
            new URLSearchParams({
                client_id: outlookConfig.clientId,
                redirect_uri: outlookConfig.redirectUri,
                scope: outlookConfig.scope,
                state: OIDCClient.getState(OUTLOOK_PREFIX),
            })
        );
    }

    /**
     * @param {string} url
     */
    static async processConnectOutlook(url) {
        const params = new URLSearchParams(url.split('?')[1]);

        if (params.has('error')) {
            throw new Error(`An azure error occurred: ${params.get('error_description')}`);
        }

        const adminConsent = params.get('admin_consent');
        if (adminConsent !== 'True') {
            throw new Error('Admin consent not given.');
        }

        const state = params.get('state');
        if (!OIDCClient.compareState(state, OUTLOOK_PREFIX)) {
            throw new Error('Invalid state.');
        }

        OIDCClient.clearState(OUTLOOK_PREFIX);
    }

    /**
     * @param {string} url
     */
    static async processConnectGoogleCalendar(url) {
        const params = new URLSearchParams(url.split('?')[1]);

        if (params.has('error')) {
            throw new Error(`An google calendar error occurred: ${params.get('error')}`);
        }

        const state = params.get('state');
        if (!OIDCClient.compareState(state, GOOGLE_CALENDAR_PREFIX)) {
            throw new Error('Invalid state.');
        }

        OIDCClient.clearState(GOOGLE_CALENDAR_PREFIX);
    }

    static connectGoogleCalendar() {
        OIDCClient.createState(GOOGLE_CALENDAR_PREFIX);

        const googleCalendarConfig = getGoogleCalendarConfig();

        this.redirect(
            googleCalendarConfig.url,
            new URLSearchParams({
                client_id: googleCalendarConfig.clientId,
                redirect_uri: googleCalendarConfig.redirectUri.toString(),
                scope: googleCalendarConfig.scope,
                response_type: googleCalendarConfig.responseType,
                state: OIDCClient.getState(GOOGLE_CALENDAR_PREFIX),
                access_type: googleCalendarConfig.accessType,
                approval_prompt: googleCalendarConfig.approvalPrompt,
            })
        );
    }
}
