import router from '../../router/router';

export const GOOGLE_CALENDAR_PREFIX = 'google';

export const getGoogleCalendarConfig = () => ({
    url: 'https://accounts.google.com/o/oauth2/auth',
    clientId: import.meta.env.VITE_OAUTH_GOOGLE_ID,
    redirectUri:
        window.location.origin.replace(new RegExp(/(https:\/\/)([\w-]+)(\..+)/i), '$1app$3') +
        router.resolve({name: 'AdminCalendarSettings'}).href,
    scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/admin.directory.user.readonly',
    ].join(' '),
    responseType: 'code',
    accessType: 'offline',
    approvalPrompt: 'force',
});
