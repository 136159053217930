export class HandledApiError extends Error {
    constructor({status, statusText, type, json, silent, err}) {
        super(`[${status}]: ${statusText}`);
        this.name = 'HandledApiError';
        this.status = status;
        this.statusText = statusText;
        this.type = type;
        this.json = json;
        this.silent = silent;
        this.err = err;
        this.cause = err;
    }
}
