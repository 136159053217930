import {defineStore} from 'pinia';
import {useLocalStorage} from '@vueuse/core';
import AccountForm from '../api/models/form/AccountForm';
import {computed, readonly} from 'vue';
import * as Sentry from '@sentry/vue';

const accountSerializer = {
    read: value => (value ? new AccountForm(JSON.parse(value)) : null),
    write: value => JSON.stringify(value),
};

export const useAccountStore = defineStore('account', () => {
    /**
     * The instance of the current users account, stored in local storage.
     * @type {import('@vueuse/core').RemovableRef<AccountForm|null>}
     */
    const account = useLocalStorage('account', null, {serializer: accountSerializer});

    /**
     * The instance of the current users account. Readonly.
     * @type {import('vue').Ref<AccountForm|null>}
     */
    const readonlyAccount = readonly(account);

    /**
     * Whether the current account has an active license
     * @type {import('vue').ComputedRef<boolean>}
     */
    const accountHasActiveLicense = computed(() =>
        ['Free', 'Corporate', 'Enterprise', 'Enterprise Plus'].includes(
            account.value?.subscription?.type
        )
    );

    /**
     * Whether the current account is trial
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isTrial = computed(() => account.value.subscription?.type === 'Trial');

    /**
     * Updates the stored account object.
     *
     * @param {AccountForm| Object | null} value Will be mapped to `AccountForm`
     * @return void
     */
    function updateAccount(value) {
        if (!(value instanceof AccountForm) && value !== null) {
            value = new AccountForm(value);
        }
        account.value = value;
        addDiagnosticData(account.value);
    }

    /**
     * @param {...string} licenseType
     * @returns {boolean}
     */
    function currentAccountHasLicenseType(licenseType) {
        return licenseType.includes(account.value?.subscription?.type);
    }

    return {
        account: readonlyAccount,
        accountHasActiveLicense,
        isTrial,
        updateAccount,
        currentAccountHasLicenseType,
    };
});

/**
 * Add a sentry breadcrumb for debugging purposes
 * @param {AccountForm} account
 */
function addDiagnosticData(account) {
    if (!account) {
        return;
    }

    if (typeof account.subscription === 'undefined') {
        Sentry.addBreadcrumb({
            sev: 'default',
            data: account,
            message: 'Account is missing subscription data',
        });
    }
}
