import API from '../api';
import UnauthenticatedError from '../helpers/UnauthenticatedError';
import CredentialsError from '../helpers/CredentialsError';
import {useLocaleStore} from '../../stores/localeStore';
import {useSecurityStore} from '../../stores/securityStore';
import {useInformationStore} from '../../stores/informationStore';
import router from '../../router/router';
import AccountRegistrationSurveyForm from '../models/form/AccountRegistrationSurveyForm';

export default () => ({
    login(data) {
        const body = JSON.stringify({
            username: data.email,
            password: data.password,
        });

        const {currentLocale} = useLocaleStore();
        const informationStore = useInformationStore();

        return fetch(`/${currentLocale}/api/authorize/accessToken`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body,
        })
            .then(API.handle)
            .catch(error => {
                throw new CredentialsError(error);
            })
            .catch(API.error)
            .then(json => {
                const customDomain =
                    json.data.accountInformation.customDomain || window.defaultSubdomain;
                const hostname = window.location.hostname;
                const subDomain = hostname.split('.').shift();

                if (customDomain !== subDomain) {
                    const origin = window.location.origin;
                    const url = origin.replace(hostname, hostname.replace(subDomain, customDomain));

                    const searchParams = new URLSearchParams();
                    searchParams.append('token', json.token);
                    searchParams.append('refreshToken', json.refreshToken);

                    window.location.href = `${url}/login?${searchParams.toString()}`;

                    return;
                }

                const securityStore = useSecurityStore();

                securityStore.token = json.token;
                securityStore.refreshToken = json.refreshToken;

                informationStore.information = json.data;
                informationStore.isInitialized = true;
            });
    },
    forgotPassword(data) {
        return API.postJSON('/api/user/forgot_password', data).then(API.handle).catch(API.error);
    },
    registerUser(data) {
        return API.postJSON('/api/register/user', data, false).then(API.handle).catch(API.error);
    },
    registerAccount(data) {
        return API.postJSON('/api/register/account', data, false).then(API.handle).catch(API.error);
    },
    editAccountRegistrationSurvey(accountId, source, moreInformation) {
        return API.postJSON(
            `/api/register/${accountId}/survey`,
            new AccountRegistrationSurveyForm(source, moreInformation)
        )
            .then(API.handle)
            .catch(API.error);
    },
    resendAccountRegistrationEmail(accountId) {
        return API.postJSON(`/api/register/${accountId}/email`, {})
            .then(API.handle)
            .catch(API.error);
    },
    getPasswordGuidelines(data) {
        return API.postJSON('/api/user/password-reset/check', data)
            .then(API.handle)
            .catch(API.error);
    },
    resetPassword(data, user, token) {
        const urlParams = new URLSearchParams();
        urlParams.append('token', token);

        return API.postJSON(`/api/user/reset_password/${user}?${urlParams.toString()}`, data)
            .then(API.handle)
            .catch(API.error);
    },
    refresh(refreshToken) {
        const formData = new FormData();
        formData.set('refreshToken', refreshToken);

        return API.post('/api/authorize/refreshToken', formData)
            .then(response => API.handle(response, true))
            .catch(error => {
                if (error.status === 401 || error.status === 400) {
                    throw new UnauthenticatedError();
                }

                return API.error(error);
            });
    },
    confirmUser(email, token) {
        const urlParams = new URLSearchParams();

        urlParams.append('email', email);
        urlParams.append('token', token);

        return API.post(`/api/user/confirm?${urlParams.toString()}`, null)
            .then(API.handle)
            .catch(API.error);
    },
    refreshData(withAccount = null) {
        let additional = '';

        if (withAccount) {
            additional = '?account=' + withAccount;
        }

        return API.get('/api/information' + additional)
            .then(API.handle)
            .catch(API.error)
            .catch(error => {
                // special case for error 409 (multiple accounts found for domain)
                if (error.status !== 409) {
                    return;
                }

                router.push({name: 'Login'});
            });
    },
    impersonate(userId) {
        return API.put(`/api/impersonate/${userId}`).then(API.handle).catch(API.error);
    },
    logout() {
        const {refreshToken} = useSecurityStore();
        const formData = new FormData();
        formData.set('refreshToken', refreshToken);

        return API.post('/api/logout', formData).then(API.handle).catch(API.error);
    },
    verifySSO(token) {
        return fetch(`/api/user/verify-sso/${token}`, {
            method: 'GET',
        })
            .then(response => API.handle(response, true))
            .catch(API.error);
    },
    resendUserVerificationEmail(userId) {
        return API.postJSON(`/api/register/user/email/${userId}`, {})
            .then(API.handle)
            .catch(API.error);
    },
});
