import {translate} from '../../../shared/mixins/translation';
import TimeframeView from './TimeframeView.js';

export default class AvailableLocation {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.timeframes = json.timeframes.map(timeframe => {
            const timeframeView = new TimeframeView(timeframe);
            timeframeView.name = translate(timeframe.name);
            return timeframeView;
        });
        this.timezone = json['timezone'];
        this.itemCount = json['itemCount'];
        this.hotDeskingActive = json['hotDeskingActive'];
    }
}
