import CharacteristicView from './CharacteristicView';
import {translate} from '../../../shared/mixins/translation';
import AuthorizationGroup from '../form/AuthorizationGroup.js';
import DayBookingOption from '../DayBookingOption';

export default class MeetingRoomView {
    constructor(json) {
        this.id = json.id;
        this.number = json.number;
        this.name = json.name ?? '';
        this.locationX = json.locationX;
        this.locationY = json.locationY;
        this.defaultName = translate('app.meetingRoomDetails.meetingRoom_name', {
            '%number%': this.number,
        });
        this.blocked = json.blocked;
        this.characteristics =
            json.characteristics?.map(characteristic => new CharacteristicView(characteristic)) ??
            [];
        this.floorName = json.floorName;
        this.locationName = json.locationName;
        this.locationId = json.locationId;
        this.room = json.room;
        this.roomName = json.roomName;
        this.authorizationGroups =
            json.authorizationGroups?.map(
                authorizationGroup =>
                    new AuthorizationGroup(
                        authorizationGroup.space,
                        authorizationGroup.company,
                        authorizationGroup.departments,
                        authorizationGroup.teams,
                        authorizationGroup.users
                    )
            ) ?? [];
        this.calendarResourceId = json.calendarResourceId;
        this.occupancies =
            json.occupancies?.map(occupancy => new DayBookingOption(occupancy)) ?? [];
    }

    static createEmpty() {
        return new MeetingRoomView({
            id: '',
            number: -1,
            name: '',
            locationX: -1,
            locationY: -1,
            defaultName: '',
            blocked: false,
            characteristics: [],
            floorName: '',
            locationName: '',
            locationId: '',
            room: '',
            roomName: '',
            authorizationGroups: [],
            calendarResourceId: '',
        });
    }
}
