export default class RoomView {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.roomPlanPath = json.roomPlanPath;
        this.roomPlanHeight = json.roomPlanHeight;
        this.roomPlanWidth = json.roomPlanWidth;
        this.floor = json.floor;
        this.floorName = json.floorName;
        this.locationName = json.locationName;
        this.seats = json.seats;
        this.authorizationGroups = json.authorizationGroups;
    }
}
