const SELECT_DAY = 'SELECT_DAY',
    DESELECT_DAY = 'DESELECT_DAY',
    RESET = 'RESET';

const state = () => ({
    selectedDays: [],
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {String} day string of datetime to select
     */
    toggleDay({commit, state}, day) {
        if (state.selectedDays.includes(day)) {
            commit(DESELECT_DAY, day);
            return;
        }

        commit(SELECT_DAY, day);
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    reset({commit}) {
        commit(RESET);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} day string of datetime to select
     */
    [SELECT_DAY](state, day) {
        state.selectedDays.push(day);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} dayToRemove string of datetime to deselect
     */
    [DESELECT_DAY](state, dayToRemove) {
        state.selectedDays = state.selectedDays.filter(day => day !== dayToRemove);
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET](state) {
        state.selectedDays = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
