<template>
    <button
        class="flex h-12 w-full items-center justify-center gap-2 overflow-hidden rounded-full bg-primary px-4 text-white focus:outline-none active:bg-hover"
        :class="[
            disabled ? 'cursor-not-allowed opacity-50' : 'lg:hover:bg-hover',
            {'md:w-full': size === 'full'},
            {'md:w-108': size === 'xl' || size === 'l'},
            {'md:w-64': size === 's'},
        ]"
        :disabled="disabled"
    >
        <slot name="iconLeft" />
        <loading-spinner v-if="loading" class="mr-0.5 size-8" white />
        <external-link-icon v-if="isExternal" />
        <span v-text="text" />
        <iframe
            v-if="showStars"
            class="size-5"
            src="https://lottie.host/?file=cc20ec28-c1ed-41df-9d27-78626106a0f9/9iBPaUNdVp.json"
        />
    </button>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner.vue';
import ExternalLinkIcon from '../icons/ExternalLinkIcon.vue';

export default {
    components: {
        ExternalLinkIcon,
        LoadingSpinner,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        size: {
            type: String,
            default: 'full',
        },
        loading: {
            type: Boolean,
        },
        isExternal: {
            type: Boolean,
        },
        showStars: {
            type: Boolean,
        },
    },
};
</script>
