import FilterRepository from '../../api/repositories/FilterRepository';
import {useLocationsStore} from '../../stores/locationsStore';

const UPDATE_SELECTED_LOCATION = 'UPDATE_SELECTED_LOCATION',
    SET_LOADING_STATE = 'SET_LOADING_STATE';

const state = () => ({
    selectedLocation: null,
    locationLoading: true,
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     */
    resetLocation({commit}) {
        commit(UPDATE_SELECTED_LOCATION);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch dispatch another action
     * @param {String} locationId id of selected location
     */
    async selectLocation({commit, dispatch}, locationId) {
        commit(SET_LOADING_STATE, true);

        const {filteredLocations} = useLocationsStore();

        if (!filteredLocations.some(location => location.id === locationId)) {
            locationId = filteredLocations.at(0)?.id || null;
        }

        commit(UPDATE_SELECTED_LOCATION, locationId);

        await dispatch('fetchFilter', locationId);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch call other action
     * @param {String} location id of location to fetch filters from
     */
    fetchFilter({commit, dispatch}, location) {
        return FilterRepository()
            .browse(location)
            .then(locationFilter => {
                dispatch(
                    'filterFloor/updateAvailableFloors',
                    locationFilter.find(filter => filter.type === 'floors')?.filters,
                    {root: true}
                );
                dispatch(
                    'filterRoom/updateAvailableRooms',
                    locationFilter.find(filter => filter.type === 'rooms')?.filters,
                    {root: true}
                );
                dispatch(
                    'filterCharacteristic/updateAvailableCharacteristics',
                    locationFilter.find(filter => filter.type === 'characteristics')?.filters,
                    {root: true}
                );
                dispatch('filterDay/reset', false, {root: true});
                dispatch(
                    'filterTimeframe/updateAvailableTimeframes',
                    locationFilter.find(filter => filter.type === 'timeframes')?.filters,
                    {root: true}
                );

                dispatch('filterSeat/resetSeat', {}, {root: true});
                dispatch('filterMeetingRoom/resetMeetingRoom', {}, {root: true});
                dispatch('filterZone/resetZone', {}, {root: true});
                dispatch('filterCharacteristic/reset', false, {root: true});
                dispatch('filterTime/defaultSelect', {}, {root: true});

                //select default room and fetch resource list
                dispatch('filterRoom/defaultSelect', true, {root: true});

                commit(SET_LOADING_STATE, false);
            });
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Boolean} loadingState locations loading state
     */
    [SET_LOADING_STATE](state, loadingState) {
        state.locationLoading = loadingState;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} location id of location
     */
    [UPDATE_SELECTED_LOCATION](state, location) {
        state.selectedLocation = location;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
