import featureFlags from './modules/featureFlags';
import filterCharacteristic from './modules/filterCharacteristic';
import filterDay from './modules/filterDay';
import filterFloor from './modules/filterFloor';
import filterLocation from './modules/filterLocation';
import filterRoom from './modules/filterRoom';
import filterSeat from './modules/filterSeat';
import filterTimeframe from './modules/filterTimeframe';
import filterZone from './modules/filterZone';
import presentList from './modules/presentList';
import resources from './modules/resources';
import seatCalendar from './modules/seatCalendar';
import filterTime from './modules/filterTime';
import filterMeetingRoom from './modules/filterMeetingRoom';
import weekOverview from './modules/weekOverview';
import {createStore} from 'vuex';

const debug = import.meta.env.DEV;

export default createStore({
    modules: {
        presentList,
        resources,
        filterLocation,
        filterFloor,
        filterRoom,
        filterCharacteristic,
        filterDay,
        filterSeat,
        filterZone,
        filterTimeframe,
        seatCalendar,
        featureFlags,
        filterTime,
        filterMeetingRoom,
        weekOverview,
    },
    strict: debug,
});
