import UnauthenticatedError from '../../api/helpers/UnauthenticatedError';
import {useSecurityStore} from '../../stores/securityStore';
import {useInformationStore} from '../../stores/informationStore';

/**
 * Runs initial refresh token / information call logic
 * @return {NavigationGuardResult}
 */
export const refreshInformationData = async to => {
    const securityStore = useSecurityStore();
    const informationStore = useInformationStore();

    if (informationStore.isInitialized) {
        return;
    }

    // Special handling for routes that do not differentiate between
    // authenticated and unauthenticated users
    //
    // Currently only used of kiosk mode pages
    if (to.meta.skipTokenRefresh) {
        return;
    }

    try {
        await securityStore.refreshAccessToken();
    } catch (err) {
        if (!(err instanceof UnauthenticatedError)) {
            throw err;
        }
    }
};
