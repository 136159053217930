import API from '../api';
import AdminFeatures from '../models/view/AdminFeatures';
import UserFeatures from '../models/view/UserFeatures';

export default () => ({
    browseAdminFeatures() {
        return API.get('/api/admin/features')
            .then(API.handle)
            .catch(API.error)
            .then(json => new AdminFeatures(json));
    },
    browseUserFeatures() {
        return API.get('/api/features')
            .then(API.handle)
            .catch(API.error)
            .then(json => new UserFeatures(json));
    },
});
