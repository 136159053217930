/**
 * Gets a single value from a LocationQuery entry.
 * @param {import('vue-router').LocationQueryValue | import('vue-router').LocationQueryValue[]} queryValue
 * @return {import('vue-router').LocationQueryValue}
 */
export const unwrapRouteQueryValue = queryValue => {
    if (queryValue instanceof Array) {
        return queryValue[0];
    }

    return queryValue;
};
