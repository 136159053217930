import moment from 'moment-timezone';

export default {
    methods: {
        /**
         * @return {Object} momentObject
         */
        getRoundedNow() {
            const roundedUp = Math.ceil(moment().minute() / 15) * 15;
            return moment().minute(roundedUp).second(0);
        },
        /**
         *
         * @param {Object} momentObject
         * @returns {string} HH:mm:ss
         */
        formatApiTime(momentObject) {
            return moment(momentObject).second(0).format('HH:mm:ss');
        },
        /**
         *
         * @param {String} time
         * @returns {Object} momentObject
         */
        formatTimeToDateTime(time) {
            const hours = time.slice(0, 2);
            const minutes = time.slice(3, 5);
            return moment().set({hour: hours, minute: minutes}).second(0);
        },
        /**
         * @param {string|Date} from
         * @param {string|Date} until
         * @returns {string}
         */
        formatTimeSpan(from, until) {
            return `${moment(from).format('HH:mm')} - ${moment(until).format('HH:mm')}`;
        },
        /**
         * @param {string|Date} time HH:mm:ss
         * @returns {string} HH:mm
         */
        formatTimeToHoursAndMinutes(time) {
            return moment(time, 'HH:mm:ss').format('HH:mm');
        },
        /**
         *
         * @returns {string} time now formatted
         */
        getNowFormatted() {
            return moment(this.getRoundedNow()).format();
        },
        /**
         *
         * @returns {string} time one hour later formatted
         */
        getRoundedAfterOneHour() {
            return moment(this.getRoundedNow()).add(1, 'hour').format();
        },
        /**
         * @param {object[]} timeframeables
         * @param {boolean} withFallback
         * @returns {object} the closest day booking
         */
        findClosestTimeframeable(timeframeables, withFallback = true) {
            const now = moment().toDate();

            const foundTimeframe = timeframeables
                .sort(
                    (timeframeableA, timeframeableB) =>
                        moment(timeframeableA, 'HH:mm:ss').toDate() -
                        moment(timeframeableB).toDate()
                )
                .find(({from, until}) => {
                    const momentFrom = moment(from, 'HH:mm:ss').toDate();
                    const momentUntil = moment(until, 'HH:mm:ss').toDate();

                    if (momentFrom <= now && momentUntil >= now) {
                        return true;
                    }

                    return momentFrom >= now;
                });

            if (!foundTimeframe && withFallback) {
                return timeframeables[0];
            }

            return foundTimeframe;
        },
        /**
         * @returns {string[]} All hours in the day in format HH:mm
         */
        getDayHours() {
            return new Array(24).fill().map((acc, index) => moment({hour: index}).format('HH:mm'));
        },
        /**
         * @param {String}  time
         * @returns {number} time in quarters
         */
        calculateQuarters(time) {
            return (moment(time, 'HH:mm').minutes() + moment(time, 'HH:mm').hours() * 60) / 15;
        },
        /**
         * @param {String}  from
         * @param {String}  until
         * @returns {number} time difference in quarters
         */
        calculateTimeDifferenceInQuarters(from, until) {
            return (
                moment.duration(moment(until, 'HH:mm').diff(moment(from, 'HH:mm'))).asMinutes() / 15
            );
        },
        /**
         * @returns {number} count past moments of the day
         */
        getMomentsOfToday() {
            return moment().diff(moment().clone().startOf('day'), 'minutes');
        },
        /**
         * @param {string} time1
         * @param {string} time2
         * @returns {string} earlier time (HH:mm:ss)
         */
        getEarlierTime(time1, time2) {
            if (time1.slice(0, 2) <= time2.slice(0, 2)) {
                if (time1.slice(3, 5) <= time2.slice(3, 5)) {
                    return time1;
                }
            }
            return time2;
        },
        /**
         * @param {string} time1
         * @param {string} time2
         * @returns {string} later time (HH:mm:ss)
         */
        getLaterTime(time1, time2) {
            if (time1.slice(0, 2) >= time2.slice(0, 2)) {
                if (time1.slice(3, 5) >= time2.slice(3, 5)) {
                    return time1;
                }
            }
            return time2;
        },
        /**
         * @param {string} time time to format (HH:mm)
         * @param {string} timezone timezone to format with
         * @returns {string} formatted time (HH:mm)
         */
        formatUtcTimeWithTimezone(time, timezone) {
            return moment.tz(moment.utc(time, 'HH:mm'), timezone).format('HH:mm');
        },
    },
};
