import {ref, watch} from 'vue';
import {getCookieValue, removeCookie, saveCookieValue} from '../../api/helpers/cookieHelper';

/**
 * Creates a ref that is synced to a cookie.
 * @param key
 * @return {import('vue').Ref<string|null>}
 *
 */
export function useCookie(key) {
    const value = ref(getCookieValue(key));

    watch(value, newValue => {
        if (newValue) {
            saveCookieValue(key, newValue);
        } else {
            removeCookie(key);
        }
    });

    return value;
}
