export default class FilteredUserDayBookingsView {
    constructor(json) {
        this.id = json.id;
        this.from = json.from;
        this.until = json.until;
        this.floorName = json.floorName;
        this.locationName = json.locationName;
        this.resourceName = json.resourceName;
        this.resourceType = json.resourceType;
        this.roomName = json.roomName;
        this.date = json.date;
    }
}
