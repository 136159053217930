import {defineStore} from 'pinia';
import AuthenticationRepository from '../api/repositories/AuthenticationRepository';
import {computed, watch} from 'vue';
import {syncRefs, useLocalStorage, until, useAsyncState} from '@vueuse/core';
import AuthenticationSettingsView from '../api/models/view/AuthenticationSettingsView';
import {useAccountStore} from './accountStore';

const serializer = {
    read: value => (value ? new AuthenticationSettingsView(JSON.parse(value)) : null),
    write: value => JSON.stringify(value),
};

export const useAuthenticationSettings = defineStore('authenticationSettings', () => {
    /**
     *
     * @type {import('@vueuse').RemovableRef<AuthenticationSettingsView|null>}
     */
    const storedAuthenticationSettings = useLocalStorage('authenticationSettings', null, {
        serializer: serializer,
    });

    const accountStore = useAccountStore();

    const subDomain = computed(
        () => accountStore.account?.customDomain || window.location.hostname.split('.').shift()
    );

    const {
        state: loadedAuthenticationSettings,
        isLoading: authenticationSettingsLoading,
        execute: reloadAuthenticationSettings,
    } = useAsyncState(
        async () => {
            if (!subDomain.value) {
                return null;
            }

            const result = await AuthenticationRepository().authSettings(subDomain.value);
            return new AuthenticationSettingsView(result);
        },
        null,
        {immediate: true}
    );

    watch(subDomain, () => {
        void reloadAuthenticationSettings();
    });

    syncRefs(loadedAuthenticationSettings, storedAuthenticationSettings, {immediate: false});

    /**
     * Current authentication settings
     * @type {import('vue').ComputedRef<AuthenticationSettingsView>}
     */
    const authenticationSettings = computed(
        () => loadedAuthenticationSettings.value ?? storedAuthenticationSettings.value
    );

    /**
     * Whether OIDC is enabled for the current account.
     * @type {import('vue').ComputedRef<boolean>}
     */
    const openIdConnectEnabled = computed(
        () => !!authenticationSettings.value?.openIdConnectEnabled
    );

    /**
     * Returns a promise that resolves when authentication settings are done loading
     * @return {Promise<void>}
     */
    async function ensureAuthenticationSettingsLoaded() {
        await until(authenticationSettingsLoading).not.toBeTruthy();
    }

    return {
        authenticationSettings,
        openIdConnectEnabled,
        authenticationSettingsLoading,
        ensureAuthenticationSettingsLoaded,
        reloadAuthenticationSettings,
    };
});
