<template>
    <svg class="size-5 fill-current" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#externalLinkClipPath)">
            <path
                d="M15 2.5c.691 0 1.25.559 1.25 1.25v12.5c0 .691-.559 1.25-1.25 1.25H2.5c-.691 0-1.25-.559-1.25-1.25V3.75c0-.691.559-1.25 1.25-1.25H15Zm2.5 1.25c0-1.379-1.121-2.5-2.5-2.5H2.5A2.502 2.502 0 0 0 0 3.75v12.5c0 1.379 1.121 2.5 2.5 2.5H15c1.379 0 2.5-1.121 2.5-2.5V3.75ZM6.562 6.25a.627.627 0 0 0-.625.625c0 .344.282.625.625.625h3.805l-5.183 5.184a.627.627 0 0 0 0 .882c.242.243.64.243.882 0l5.184-5.183V12.5c0 .344.281.625.625.625a.627.627 0 0 0 .625-.625V6.875a.627.627 0 0 0-.625-.625H6.562Z"
            />
        </g>
        <defs>
            <clipPath id="externalLinkClipPath"><path d="M0 0h17.5v20H0z" fill="#fff" /></clipPath>
        </defs>
    </svg>
</template>
