import UserView from '../../api/models/view/UserView.js';

const roleHierarchy = {
    [UserView.ROLE_SUPERADMIN]: [],
    [UserView.ROLE_ADMIN]: [UserView.ROLE_SUPERADMIN],
    [UserView.ROLE_MANAGER]: [UserView.ROLE_SUPERADMIN, UserView.ROLE_ADMIN],
    [UserView.ROLE_ANALYST]: [UserView.ROLE_SUPERADMIN, UserView.ROLE_ADMIN, UserView.ROLE_MANAGER],
    [UserView.ROLE_USER]: [
        UserView.ROLE_SUPERADMIN,
        UserView.ROLE_ADMIN,
        UserView.ROLE_MANAGER,
        UserView.ROLE_ANALYST,
        UserView.ROLE_USER,
    ],
};

export const isGranted = (role, subject) => {
    if (!subject) {
        return false;
    }

    const roles = subject.roles;

    if (roles.includes(role)) {
        return true;
    }

    return !!roleHierarchy[role].some(superRole => roles.includes(superRole));
};
