export default class WeekOverviewUserInformationEntry {
    constructor(json) {
        this.bookingCount = json.bookingCount;
        this.date = json.date;
        this.from = json.from;
        this.until = json.until;
        this.userStatus = json.userStatus;
        this.hidden = json.hidden;
    }
}
