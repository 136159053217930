export default function extractFormDataFromObject(data, formPrefix) {
    const formData = new FormData();

    const flattenedData = flattenObject(data);

    for (const key of Object.keys(flattenedData)) {
        if (flattenedData[key] === null) {
            continue;
        }
        formData.set(formPrefix + key, flattenedData[key]);
    }

    return formData;
}

function flattenObject(data, prefix = '') {
    const flattenedObject = {};
    for (const key of Object.keys(data)) {
        if (data[key] === null) {
            continue;
        }

        if (Array.isArray(data[key])) {
            Object.assign(flattenedObject, flattenObject({...data[key]}, `${prefix}[${key}]`));

            continue;
        }

        if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
            Object.assign(flattenedObject, flattenObject(data[key], `${prefix}[${key}]`));

            continue;
        }

        flattenedObject[`${prefix}[${key}]`] = data[key];
    }

    return flattenedObject;
}
