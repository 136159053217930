import LocationView from './LocationView';

export default class CharacteristicView {
    constructor(json) {
        this.id = json.id;
        this.name = (json.name || json.term) ?? '';

        if (json.location) {
            this.location = new LocationView(json.location);
        }
    }
}
