import {useToast} from '../shared/components/toast/useToast.js';
import {translate} from '../shared/mixins/translation.js';
import {useSecurityStore} from '../stores/securityStore';
import OIDCClient from '../api/authentication/OIDCClient';
import {useAccountStore} from '../stores/accountStore';
import router from './router';
import SSORepository from '../api/repositories/SSORepository.js';

export const authenticationGuard = async to => {
    if (to.name !== 'GoogleCallback') {
        return;
    }

    async function getAuthenticationGuardResult() {
        const authorizationCode = to.query.code;
        const state = to.query.state;

        if (!OIDCClient.compareState(state)) {
            OIDCClient.clearStateAndNonce();
            useToast().error(translate('app.toast.login.oidc.state_wrong'));
            return {name: 'Login'};
        }

        OIDCClient.clearStateAndNonce();

        if (!authorizationCode) {
            useToast().error(translate('app.toast.login.missing_accesstoken'));
            return {name: 'Login'};
        }

        const json = await SSORepository().handleLogin(authorizationCode, 'google');

        const securityStore = useSecurityStore();
        securityStore.token = json.access_token;
        securityStore.refreshToken = json.refresh_token;

        return {name: 'Home'};
    }

    const result = await getAuthenticationGuardResult();

    if (!result) {
        return result;
    }

    // Detect whether the account of the authorized users has a custom subdomain
    const accountStore = useAccountStore();
    const regex = /^(https:\/\/)(\w+)(\.)/i;
    const parts = regex.exec(window.location.origin);
    const currentSubdomain = parts[2];
    const customSubdomain = accountStore.account?.customDomain || 'app';

    // If the current subdomain doesn't match the account's subdomain, redirect to the correct subdomain
    if (currentSubdomain !== customSubdomain) {
        const route = window.location.origin + router.resolve(result).href;

        window.location.href = route.replace(regex, '$1' + customSubdomain + '$3');

        return await new Promise(() => {});
    }

    return result;
};
