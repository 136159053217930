import {useLocaleStore} from '../../stores/localeStore';
import {debug} from '../../shared/helpers/debug';

/**
 *
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export function adjustUrlLocale(to) {
    const {currentLocale, updateLocale, availableLanguages} = useLocaleStore();
    //adjust locale in url if changed
    const [, lang] = to.href.split('/');

    if (lang !== currentLocale) {
        debug.log('locale adjustment');
        window.location.href = `${window.location.origin}/${currentLocale}${to.fullPath}`;

        return new Promise(() => {});
    }

    // adjust locale in url (from initial url)
    const localeString = availableLanguages.join('|');
    const pattern = new RegExp(`^/(${localeString})(/.*)?`);
    const parts = pattern.exec(to.fullPath);
    if (!parts) {
        return;
    }

    const locale = parts[1] || null;

    if (!availableLanguages.includes(locale)) {
        return;
    }

    debug.log('locale adjustment');

    updateLocale(locale);
    window.location.href = to.fullPath;

    return new Promise(() => {});
}
