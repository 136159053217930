/**
 * @param {Object} routeObject
 * @returns {boolean}
 */
function isValidCookieRoute(routeObject) {
    return (
        !['Login', 'Home', 'Logout', 'GoogleCallback'].includes(routeObject?.name) &&
        !!routeObject?.name
    );
}

/**
 * @param route {string}
 */
export function saveRouteCookie(route) {
    const routeObject = JSON.parse(route);
    if (!isValidCookieRoute(routeObject)) {
        return false;
    }

    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 2);

    saveCookieValue('route', route, expires.toUTCString());
}
/**
 * @returns {object|null}
 */
export function getRouteFromCookie() {
    const route = getCookieValue('route');

    if (!route) {
        return null;
    }

    try {
        const routeObject = JSON.parse(route);
        return isValidCookieRoute(routeObject) ? routeObject : null;
    } catch {
        return null;
    }
}
/**
 * @param key {string}
 * @returns {string|null}
 */
export function getCookieValue(key) {
    const cookie = document.cookie;

    if (!cookie) {
        return null;
    }

    const parts = cookie.split(';').map(entry => entry.trim());

    const value = parts.find(entry => entry.startsWith(`${key}=`));

    if (!value) {
        return null;
    }

    return value.substring(key.length + 1);
}

/**
 * @param key {string}
 * @param value {string}
 * @param expires {string|null}
 */
export function saveCookieValue(key, value, expires = null) {
    let cookieString = `${key}=${value}; path=/; secure; `;

    // check for cookie size limit of 4096
    if (new TextEncoder().encode(cookieString).length > 4096) {
        // eslint-disable-next-line no-console
        console.error(
            `The value for ${value.substring(0, 20)}... exceeds the cookie limit of 4096 bytes.`
        );
    }

    if (expires) {
        cookieString += `expires=${expires}; `;
    }

    const hostname = window.location.hostname;
    const host = hostname.substring(hostname.indexOf('.') + 1);

    if (host !== 'localhost') {
        cookieString += `domain=${host}; `;
    }

    document.cookie = cookieString;
}

/**
 * @param key {string}
 */
export function removeCookie(key) {
    const expires = new Date(1970, 1, 1);

    saveCookieValue(key, '', expires.toUTCString());
}

export function cleanupCookieRoute() {
    const hostname = window.location.hostname;
    const host = hostname.substring(hostname.indexOf('.') + 1);

    document.cookie = `route=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${host}`;
}
