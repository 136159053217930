<template>
    <div class="spinner spinner--circle-4" :class="{white: white}" />
</template>

<script>
export default {
    props: {
        white: {
            type: Boolean,
        },
    },
};
</script>
