export default {
    root: () => ({
        class: [
            'flex items-center justify-center',
            'w-[42px]',
            'text-input-icon  bg-surface-100',
            'border border-surface-300',
            'first:rounded-l-md last:rounded-r-md',
        ],
    }),
};
