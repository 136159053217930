import {authorizeWithQueryToken} from './guards/authorizeWithQueryToken';
import {handleMetaProperties} from './guards/handleMetaProperties';
import {adjustUrlLocale} from './guards/adjustUrlLocale';
import {handleMsal} from './guards/handleMsal';
import {authenticationGuard} from './authenticationGuard';
import {debug} from '../shared/helpers/debug';
import {isPassRouteResult, wrapGuard} from './guardHelper';
import {refreshInformationData} from './guards/refreshInformationData';

/**
 *
 * @type {import('vue-router').NavigationGuardWithThis[]}
 */
const guards = [
    authenticationGuard,
    handleMsal,
    authorizeWithQueryToken,
    adjustUrlLocale,
    refreshInformationData,
    handleMetaProperties,
];

const runGuards = async (to, from) => {
    for (const guard of guards) {
        const wrappedGuard = wrapGuard(guard);
        const result = await wrappedGuard(to, from);

        if (!isPassRouteResult(result)) {
            return result;
        }
    }
};

export default async (to, from) => {
    debug.start('navigationGuard debug');

    debug.start('parameters');
    debug.log(to, from);
    debug.end('parameters');

    if (typeof to.redirectedFrom === 'undefined') {
        debug.log('redirectedFrom empty');
        to.redirectedFrom = from.redirectedFrom;
    }

    try {
        const result = await runGuards(to, from);

        if (isPassRouteResult(result)) {
            debug.log('let route pass');
        }

        return result;
    } catch (error) {
        if (to.name !== 'Home') {
            return {name: 'Home'};
        }

        throw error;
    } finally {
        debug.end('navigationGuard debug');
    }
};
