import SecurityRepository from '../../api/repositories/SecurityRepository';
import {useToast} from '../../shared/components/toast/useToast.js';
import {translate} from '../../shared/mixins/translation';
import UnauthenticatedError from '../../api/helpers/UnauthenticatedError';

export const confirmUser = async to => {
    const email = to.query.email;
    const token = to.query.token;

    if (email && token) {
        await SecurityRepository().confirmUser(email, token);
        useToast().success(translate('app.flash.user.register_confirmed'));
        return {name: 'Home'};
    }

    throw new UnauthenticatedError();
};
