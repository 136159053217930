export default {
    root: {
        class: [
            'flex items-center justify-center flex-wrap',
            'px-4 py-2',
            'border-0 rounded-md',
            //custom
            'bg-background',
            'text-black',
            //'bg-surface-0 dark:bg-surface-900',
            //'text-surface-500 dark:text-white/60',
        ],
    },
    first: ({context}) => ({
        class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Shape
            'border-0 rounded-full',
            // Size
            'min-w-[2.5rem] h-10 m-[0.143rem]',
            'leading-none',
            // Color
            'text-surface-500 dark:text-white/60',
            // State
            {
                'hover:bg-surface-50 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.disabled,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400':
                    !context.disabled,
            },
            // Transition
            'transition duration-200',
            // Misc
            'user-none overflow-hidden',
            {'cursor-default pointer-events-none opacity-60': context.disabled},
        ],
    }),
    prev: ({context}) => ({
        class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Shape
            'border-0 rounded-full',
            // Size
            'min-w-[2.5rem] h-10 m-[0.143rem]',
            'leading-none',
            // Color
            'text-surface-500 dark:text-white/60',
            // State
            {
                'hover:bg-surface-50 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.disabled,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400':
                    !context.disabled,
            },
            // Transition
            'transition duration-200',
            // Misc
            'user-none overflow-hidden',
            {'cursor-default pointer-events-none opacity-60': context.disabled},
        ],
    }),
    next: ({context}) => ({
        class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Shape
            'border-0 rounded-full',
            // Size
            'min-w-[2.5rem] h-10 m-[0.143rem]',
            'leading-none',
            // Color
            'text-surface-500 dark:text-white/60',
            // State
            {
                'hover:bg-surface-50 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.disabled,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400':
                    !context.disabled,
            },
            // Transition
            'transition duration-200',
            // Misc
            'user-none overflow-hidden',
            {'cursor-default pointer-events-none opacity-60': context.disabled},
        ],
    }),
    last: ({context}) => ({
        class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Shape
            'border-0 rounded-full',
            // Size
            'min-w-[2.5rem] h-10 m-[0.143rem]',
            'leading-none',
            // Color
            'text-surface-500 dark:text-white/60',
            // State
            {
                'hover:bg-surface-50 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.disabled,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400':
                    !context.disabled,
            },
            // Transition
            'transition duration-200',
            // Misc
            'user-none overflow-hidden',
            {'cursor-default pointer-events-none opacity-60': context.disabled},
        ],
    }),
    page: ({context}) => ({
        class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Shape
            'border-0 rounded-full',
            // Size
            'min-w-[2.5rem] h-10 m-[0.143rem]',
            'leading-none',
            // Color
            'text-surface-500 dark:text-white/60',
            // State
            {
                'hover:bg-surface-50 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.disabled,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400':
                    !context.disabled,
            },
            // Transition
            'transition duration-200',
            // Misc
            'user-none overflow-hidden',
            {'cursor-default pointer-events-none opacity-60': context.disabled},
        ],
    }),
    contentStart: 'mr-auto',
    contentEnd: 'ml-auto',
    pcJumpToPageInput: {
        root: {
            class: 'inline-flex mx-2',
        },
        input: {
            root: {
                class: [
                    'relative',

                    //Font
                    'font-sans',
                    'leading-6',
                    'sm:text-sm',

                    // Display
                    'block',
                    'flex-auto',

                    // Color and Background
                    'bg-transparent',
                    'border-0',
                    'text-surface-800 dark:text-white/80',
                    'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
                    // Sizing and Spacing
                    'w-[1%] max-w-[3rem]',
                    'py-1.5 px-3',

                    //Shape
                    'rounded-md',

                    // Transitions
                    'transition',
                    'duration-200',

                    // States
                    'hover:border-primary dark:hover:border-primary',
                    'focus-visible:outline-none focus-visible:shadow-none',
                    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary dark:focus-visible:ring-primary',
                    // Misc
                    'cursor-pointer',
                    'overflow-hidden overflow-ellipsis',
                    'whitespace-nowrap',
                    'appearance-none',
                ],
            },
        },
    },
    pcRowPerPageDropdown: {
        root: ({props, state}) => ({
            class: [
                // Display and Position
                'inline-flex',
                'relative',

                // Shape
                'h-8',
                'rounded-md',

                // Spacing
                'mx-2',

                // Color and Background
                'bg-transparent',

                // Transitions
                'transition-all',
                'duration-200',

                // States
                {
                    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-primary':
                        !state.focused,
                },

                // Misc
                'cursor-pointer',
                'select-none',
                {
                    'opacity-60': props.disabled,
                    'pointer-events-none': props.disabled,
                    'cursor-default': props.disabled,
                },
                //custom
                'items-center',
            ],
        }),
        input: {
            class: [
                //Font
                'font-sans',
                'leading-6',
                'sm:text-sm',

                // Display
                'block',
                'flex-auto',
                'flex items-center',

                // Color and Background
                'bg-transparent',
                'border-0',
                'text-surface-800 dark:text-white/80',

                // Sizing and Spacing
                'w-[1%]',
                'py-1.5 px-3 pr-0',

                //Shape
                'rounded-none',

                // Transitions
                'transition',
                'duration-200',

                // States
                'focus-visible:outline-none focus-visible:shadow-none',

                // Misc
                'relative',
                'cursor-pointer',
                'overflow-hidden overflow-ellipsis',
                'whitespace-nowrap',
                'appearance-none',
            ],
        },
        trigger: {
            class: [
                //Font
                'sm:text-sm',

                // Flexbox
                'flex items-center justify-center',
                'shrink-0',

                // Color and Background
                'bg-transparent',
                'text-surface-500',

                // Size
                'w-12',
                'h-8',

                // Shape
                'rounded-tr-md',
                'rounded-br-md',
            ],
        },
        dropdownIcon: {
            class: '',
        },
        panel: {
            class: [
                // Position
                'absolute top-0 left-0',
                'mt-1',

                // Shape
                'border-0',
                'rounded-md',
                'shadow-md',

                // Color
                'bg-surface-0 dark:bg-surface-800',
                'text-surface-800 dark:text-white/80',
                'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
            ],
        },
        wrapper: {
            class: [
                // Sizing
                'max-h-[15rem]',

                // Misc
                'overflow-auto',
            ],
        },
        list: {
            class: 'py-1 list-none m-0',
        },
        item: ({context}) => ({
            class: [
                // Font
                'sm:text-sm',
                'leading-none',
                {'font-normal': !context.selected, 'font-bold': context.selected},

                // Position
                'relative',

                // Shape
                'border-0',
                'rounded-none',

                // Spacing
                'm-0',
                'py-2 px-4',

                // Color
                {'text-surface-700 dark:text-white/80': !context.focused && !context.selected},
                {
                    'bg-accent-lighter': context.selected,
                    underline: context.focused && !context.selected,
                },

                // Misc
                'cursor-pointer',
                'overflow-hidden',
                'whitespace-nowrap',
            ],
        }),
    },
};
