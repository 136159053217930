import {defineStore, storeToRefs} from 'pinia';
import {computedAsync, syncRefs, useLocalStorage, useMemoize} from '@vueuse/core';
import {useLocaleStore} from './localeStore';
import {getTranslations} from '../api/repositories/TranslationRepository';
import {computed, watch} from 'vue';
import {getPrimeVueLocale} from '../shared/primevue/locales/locale';
import {usePrimeVue} from 'primevue/config';

export const useTranslationStore = defineStore('translation', () => {
    const {currentLocale} = storeToRefs(useLocaleStore());

    const storedTranslations = useLocalStorage('translations', {});

    const getTranslationsMemoized = useMemoize(locale => getTranslations(locale));

    const loadedTranslations = computedAsync(
        () => getTranslationsMemoized(currentLocale.value),
        storedTranslations.value
    );

    syncRefs(loadedTranslations, storedTranslations);

    /**
     * Readonly ref of current translations.
     * @type {import('vue').ComputedRef<Object>}
     */
    const currentTranslations = computed(() =>
        storedTranslations.value && Object.keys(storedTranslations.value).length
            ? storedTranslations.value
            : loadedTranslations.value
    );

    usePrimeVueLocaleSwitcher(currentTranslations, currentLocale);

    return {translations: currentTranslations};
});

function usePrimeVueLocaleSwitcher(translations, currentLocale) {
    const primeVue = usePrimeVue();

    const getPrimeVueLocaleMemoized = useMemoize(getPrimeVueLocale, {
        getKey: locale => locale,
    });

    watch(
        translations,
        async translations => {
            if (translations?.messages) {
                primeVue.config.locale = await getPrimeVueLocaleMemoized(
                    currentLocale.value,
                    translations
                );
            }
        },
        {immediate: true}
    );
}
