import API from '../api';
import FilterUserView from '../models/view/FilterUserView';
import WeekOverviewUserFiltering from '../models/view/WeekOverviewUserFiltering';
import store from '../../store';

export default () => ({
    filter(name, offset, limit) {
        const baseUrl = '/api/users';
        const searchParams = new URLSearchParams();

        if (name) {
            searchParams.append('name', name);
        }

        if (offset) {
            searchParams.append('offset', offset);
        }

        if (limit) {
            searchParams.append('limit', limit);
        }

        return API.get(`${baseUrl}?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw =>
                raw.data
                    .filter(
                        singleRaw =>
                            !store.state.weekOverview.selectedUsers.some(
                                selectedUser => selectedUser === singleRaw.id
                            )
                    )
                    .map(singleRaw => new FilterUserView(singleRaw))
            );
    },
    getFilteredList(year, weekNumber, users) {
        const baseUrl = '/api/week-overview/information-list/users';
        const searchParams = new URLSearchParams();

        if (year) {
            searchParams.append('year', year);
        }

        if (weekNumber) {
            searchParams.append('weekNumber', weekNumber);
        }

        if (users) {
            users.forEach(user => {
                searchParams.append('users[]', user);
            });
        }
        return API.get(`${baseUrl}?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new WeekOverviewUserFiltering(singleRaw)));
    },
});
