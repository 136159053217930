import debounce from 'lodash/debounce';
import {provide, inject} from 'vue';

const debounceTime = 200;

/**
 * Tracks a view for the current page debounced.
 *
 * The debounced function is using a relatively small debounce time to prevent
 * view tracking of automatic page changes (automatic redirects, component navigation guards etc.)
 * @type {DebouncedFuncLeading<T> | DebouncedFunc<T>}
 */
export const trackMatomoPageView = debounce(() => {
    const _paq = getMatomoInstance();
    if (!_paq) {
        return;
    }
    _paq.push(['setCustomUrl', window.location.href]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['trackPageView']);
}, debounceTime);

/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string} name
 * @param {string | number | undefined } value
 */
export function trackMatomoEvent(category, action, name, value = undefined) {
    const _paq = getMatomoInstance();
    if (!_paq) {
        return;
    }

    const trackArray = ['trackEvent', category, action];
    if (name || value) {
        trackArray.push(name);
    }
    if (value) {
        trackArray.push(value);
    }
    _paq.push(trackArray);
}

function getMatomoInstance() {
    const _paq = window._paq;
    if (_paq && typeof _paq.push === 'function') {
        return _paq;
    }

    return null;
}

/**
 * Returns an eventTracker object that allows tracking of events of a certain category.
 * If a category is passed, this category will be used and also provided to child components using `provide`.
 * If no category is passed, it will inject an event tracker that was provided by a parent, if found -
 * otherwise a no-op stub is used.
 * @param {string | undefined} category
 * @returns {{trackEvent: trackEvent}}
 */
export function useMatomoEventTracker(category = undefined) {
    /**
     * @param {string} action
     * @param {string} name
     * @param {string | number | undefined } value
     */
    function trackEvent(action, name, value = undefined) {
        trackMatomoEvent(category, action, name, value);
    }

    const key = 'eventTracker';

    if (category) {
        const eventTracker = {
            trackEvent,
        };
        provide(key, eventTracker);

        return eventTracker;
    } else {
        /**
         *
         * @type {{trackEvent: trackEvent}}
         */
        return inject(key, {trackEvent() {}});
    }
}
