const INITIALIZE_RESOURCE_TYPE = 'SET_RESOURCE_TYPE',
    SELECT_RESOURCE = 'SELECT_RESOURCE',
    RESET = 'RESET';

const state = () => ({
    resourceTypes: [],
});

const actions = {
    async initializeResourceTypes({rootState, commit}, payload) {
        return new Promise(resolve => {
            const currentActiveResourceType = rootState.resources.resourceTypes?.find(
                ({selected}) => selected
            )?.key;

            const url = window.location.href;
            const zoneRegex = new RegExp(/\/zone\//i);
            const meetingRoomRegex = new RegExp(/\/meetingRoom\//i);

            // check whether the url does not contain the resource (e.g. DayBookingOverview) but there is a current resource set
            // otherwise the default behaviour for such routes is selecting seats
            // important: this must happen before the reset because you access the previous state

            const initializeWithPreviousOne =
                !zoneRegex.test(url) &&
                !meetingRoomRegex.test(url) &&
                currentActiveResourceType !== 'seat';

            commit(RESET);

            if (payload?.accountInformation?.allowSeats === true) {
                commit(INITIALIZE_RESOURCE_TYPE, {
                    key: 'seat',
                    selected:
                        !initializeWithPreviousOne &&
                        !zoneRegex.test(url) &&
                        !meetingRoomRegex.test(url),
                });
            }

            if (payload?.accountInformation?.allowZones === true) {
                commit(INITIALIZE_RESOURCE_TYPE, {
                    key: 'zone',
                    selected:
                        zoneRegex.test(url) ||
                        (initializeWithPreviousOne && currentActiveResourceType === 'zone'),
                });
            }

            if (payload?.accountInformation?.allowMeetingRooms === true) {
                commit(INITIALIZE_RESOURCE_TYPE, {
                    key: 'meetingRoom',
                    selected:
                        meetingRoomRegex.test(url) ||
                        (initializeWithPreviousOne && currentActiveResourceType === 'meetingRoom'),
                });
            }

            if (rootState.resources.resourceTypes.every(({selected}) => !selected)) {
                commit(SELECT_RESOURCE, rootState.resources.resourceTypes[0]?.key);
            }

            resolve();
        });
    },
    selectResource({commit}, resourceKey) {
        commit(SELECT_RESOURCE, resourceKey);
    },
    reset({commit}) {
        commit(RESET);
    },
};

const mutations = {
    [INITIALIZE_RESOURCE_TYPE](state, resourceType) {
        state.resourceTypes.push(resourceType);
    },
    [SELECT_RESOURCE](state, resourceKey) {
        state.resourceTypes.forEach(resourceType => {
            resourceType.selected = resourceType.key === resourceKey;
        });
    },
    [RESET](state) {
        state.resourceTypes = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
