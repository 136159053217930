import {defineStore} from 'pinia';
import {OIDC_STATE_PREFIX} from '../api/authentication/OIDCHelper';
import {computed} from 'vue';
import {useLocalStorageWithCookies} from '../shared/composables/useLocalStorageWithCookie';

function getPrefixedKey(key, prefix = OIDC_STATE_PREFIX) {
    return `${prefix}_${key}`;
}

export const useOidcStore = defineStore('oidc', () => {
    /**
     * OIDC access token
     * @type {import('vue').WritableComputedRef<string | null>}
     */
    const accessToken = useLocalStorageWithCookies(getPrefixedKey('accessToken'), null);

    /**
     * OIDC id token
     * @type {import('vue').WritableComputedRef<string | null>}
     */
    const idToken = useLocalStorageWithCookies(getPrefixedKey('idToken'), null);

    /**
     * @type {import('vue').ComputedRef<boolean>}
     */
    const hasOidcToken = computed(() => !!accessToken.value || !!idToken.value);

    /**
     * Clears OIDC tokens
     */
    function clearOidc() {
        accessToken.value = null;
        idToken.value = null;
    }

    return {
        clearOidc,
        accessToken,
        idToken,
        hasOidcToken,
    };
});
