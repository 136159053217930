import RoomView from './RoomView';

export default class FloorView {
    constructor(json) {
        this.id = json['id'];
        this.name = json['name'];
        this.locationName = json['locationName'];
        this.locationWithFloorName = json['locationName'] + ' | ' + json['name'];
        this.rooms = [];

        if (json.rooms) {
            json['rooms'].forEach(room => {
                this.rooms.push(new RoomView(room));
            });
        }
    }
}
