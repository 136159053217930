import {useSecurityStore} from '../../stores/securityStore';
import {debug} from '../../shared/helpers/debug';
import {useInformationStore} from '../../stores/informationStore';
import type {NavigationGuardReturn, RouteLocationNormalized} from 'vue-router';

export async function handleMsal(to: RouteLocationNormalized): Promise<NavigationGuardReturn> {
    const securityStore = useSecurityStore();

    if (
        !securityStore.msal ||
        to.name === 'Logout' ||
        securityStore.blockMsal ||
        securityStore.msalHandled
    ) {
        return;
    }

    const {msalInstance} = await import('../../api/authentication/msalAuthConfig');

    debug.log('special msal handling');

    const redirectResponse = await msalInstance.handleRedirectPromise().catch(error => {
        debug.log('error in handleRedirectPromise', error);
    });

    const {refresh} = useInformationStore();

    if (redirectResponse) {
        if (redirectResponse.account) {
            msalInstance.setActiveAccount(redirectResponse.account);
        }
        securityStore.token = redirectResponse.accessToken;
        securityStore.authType = 'MS-Access-Token';

        securityStore.msalHandled = true;

        const success = await refresh();

        return success ? undefined : {name: 'Login'};
    }
}
