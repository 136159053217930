import Api from '../api.js';

export default () => ({
    async fetchAccessToken(subDomain, code, codeVerifier) {
        return await Api.postJSON(
            `/oidc/${subDomain}/fetch-access-token`,
            {
                code: code,
                codeVerifier: codeVerifier,
            },
            false
        )
            .then(Api.handle)
            .catch(Api.error);
    },
    async fetchRefreshToken() {
        return await Api.get('/oidc/exchange-refresh-token')
            .then(Api.handle)
            .catch(error => error);
    },
});
