import configDay from './configDay';
import moment from 'moment-timezone';
import {useLocaleStore} from '../../stores/localeStore';
import {useLocationsStore} from '../../stores/locationsStore';

/**
 * @param {string|number} value
 * @return {string}
 */
function doubleDigit(value) {
    return ('0' + value).slice(-2);
}

/**
 * @param {string|Date|number} value
 * @return {Date}
 */
function sanitizeDate(value) {
    if (typeof value === 'string') {
        value = value.replace(' ', 'T');
    }

    if (typeof value !== 'string' || value.includes('T')) {
        return new Date(value);
    }

    const date = new Date();
    const [year, month, day] = value.split('-');

    date.setFullYear(+year, month - 1, +day);

    return date;
}

export default {
    methods: {
        /**
         * @param {string|null} time
         * @return {Date|null}
         */
        convertTimeStringToDate(time) {
            if (!time) {
                return null;
            }

            const parts = time.split(':').map(Number);

            const date = new Date();

            date.setHours(parts[0] ?? 0, parts[1] ?? 0, parts[2] ?? 0);

            return date;
        },
        /**
         * @param {Date|null} date
         * @param {boolean} includeSeconds
         * @return {string|null}
         */
        convertDateToTimeString(date, includeSeconds = true) {
            if (!date) {
                return null;
            }

            const [hours, minutes, seconds] = [
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ].map(doubleDigit);

            let result = `${hours}:${minutes}`;

            if (includeSeconds) {
                result += `:${seconds}`;
            }

            return result;
        },
        /**
         * @returns {string} formatted to locale specific (weekday, date) or just (date)
         */
        formatDateWeekday(date, weekday = true, short = true) {
            date = sanitizeDate(date);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            };
            if (weekday) {
                options['weekday'] = short ? 'short' : 'long';
            }
            const {currentLocale} = useLocaleStore();
            const locale = currentLocale === 'en' ? 'en-GB' : currentLocale;
            return new Intl.DateTimeFormat(locale, options).format(date);
        },
        /**
         * @param {Date|string|number} date
         * @param {boolean} withTime
         * @return {string} formatted to yyyy-mm-dd
         */
        formatApiDate(date, withTime = false) {
            date = sanitizeDate(date);

            const year = date.getFullYear();
            const month = doubleDigit(date.getMonth() + 1);
            const day = doubleDigit(date.getDate());
            const dateString = `${year}-${month}-${day}`;

            if (!withTime) {
                return dateString;
            }

            const [hours, minutes, seconds] = [
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ].map(doubleDigit);

            return `${dateString}T${hours}:${minutes}:${seconds}`;
        },
        /**
         *
         * @param {Date|string|number} date to get formatted
         * @returns {string} formatted date in the form of dd.mm.
         */
        formatToDayAndMonth(date) {
            date = sanitizeDate(date);
            const options = {
                month: '2-digit',
                day: '2-digit',
            };
            const {currentLocale} = useLocaleStore();
            const locale = currentLocale === 'en' ? 'en-GB' : currentLocale;
            return new Intl.DateTimeFormat(locale, options).format(date);
        },
        /**
         * @return {number} today in ms
         */
        getToday() {
            return new Date().setHours(0, 0, 0, 0);
        },
        /**
         * @return {Date} tomorrow as Date object
         */
        getTomorrow() {
            return moment().add(1, 'day').toDate();
        },
        /**
         *
         * @param {number} n
         * @param {Date|string|number} date date to count from
         * @return {Date} day in n days from today as Date object
         */
        getDateInNDays(n, date = this.getToday()) {
            return moment(date).add(n, 'day').toDate();
        },
        /**
         *
         * @param {string} timeframeKey
         * @param {boolean} [lowercase=false]
         * @param {string} locationId
         * @return {string} timeframe translation
         */
        getTimeframeTranslation(timeframeKey, locationId, lowercase = false) {
            const {availableLocations} = useLocationsStore();
            const location = availableLocations.find(location => location.id === locationId);

            if (!location) {
                return '';
            }

            const timeframe = location.timeframes.find(
                timeframes => timeframes.key === timeframeKey
            );

            if (!lowercase) {
                return timeframe.name;
            }

            return timeframe.name.toLowerCase();
        },
        /**
         * @param {Date|string|number} date
         * @param {boolean} fullYear
         * @return {string} dd.mm.yy / dd.mm.yyyy
         */
        getFormattedDate(date, fullYear = false) {
            date = sanitizeDate(date);

            const year = fullYear ? date.getFullYear() : doubleDigit(date.getFullYear());
            const month = doubleDigit(date.getMonth() + 1);
            const day = doubleDigit(date.getDate());

            return `${day}.${month}.${year}`;
        },
        /**
         * @param {Date} date
         * @return {string} dd.mm.yy hh:mm:ss
         */
        getFormattedDateAndTime(date) {
            return `${this.formatDateWeekday(date, false)} ${this.convertDateToTimeString(date)}`;
        },
        /**
         * @param {string} dateString
         */
        dateFromString(dateString) {
            return sanitizeDate(dateString);
        },
        /**
         * @returns {string} date of today formated YYYY-MM-DD
         */
        getTodayFormatted() {
            return moment().format('YYYY-MM-DD');
        },
        /**
         * @param {string} date
         * @returns {boolean} whether the given date is in the past
         */
        isDayInPast(date) {
            return moment().startOf('day').diff(moment(date).startOf('day'), 'days') > 0;
        },
        /**
         * @param {string} date the date to get the week day for in the format YYYY-MM-DD
         * @return {string} week day of the given date in lower case, for example 'monday'
         */
        getWeekDayOfDate(date) {
            return new Date(date).toLocaleString('en', {weekday: 'long'}).toLowerCase();
        },
        /**
         *
         * @param date the date to get the corresponding month for in the format YYYY-MM-DD
         * @returns {string} short formatted localized month name
         */
        getShortMonthByDate(date) {
            date = sanitizeDate(date);
            const {currentLocale} = useLocaleStore();
            const locale = currentLocale === 'en' ? 'en-GB' : currentLocale;
            return new Intl.DateTimeFormat(locale, {month: 'short'}).format(date);
        },
    },
    computed: {
        /**
         * @return {string} formatted string of today's date
         */
        today() {
            return configDay.methods.formatApiDate(configDay.methods.getToday());
        },
    },
};
