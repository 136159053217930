import {defineStore} from 'pinia';
import {useLocalStorage} from '@vueuse/core';
import UserView from '../api/models/view/UserView';
import {isGranted} from '../shared/mixins/security';
import {computed, readonly, watch} from 'vue';
import {useSecurityStore} from './securityStore';
import {useOidcStore} from './oidcStore';
import {useAccountStore} from './accountStore';
import {useRouter} from 'vue-router';

const userSerializer = {
    read: value => (value ? new UserView(JSON.parse(value)) : null),
    write: value => JSON.stringify(value),
};

export const useUserStore = defineStore('user', () => {
    /**
     * The instance of the current user, stored in local storage.
     * @type {import('vue').RemovableRef<UserView|null>}
     */
    const user = useLocalStorage('user', null, {
        serializer: userSerializer,
    });

    /**
     * The instance of the current user. Readonly.
     * @type {import('vue').Ref<UserView|null>}
     */
    const readonlyUser = readonly(user);

    /**
     * Updates the stored user object.
     *
     * @param {UserView| Object | null} value Will be mapped to `UserView`
     * @return void
     */
    function updateUser(value) {
        if (!(value instanceof UserView) && value !== null) {
            value = new UserView(value);
        }
        user.value = value;
    }

    /**
     * Whether the current user is granted the role 'Analyst'
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isAnalyst = computed(() => isGranted(UserView.ROLE_ANALYST, user.value));

    /**
     * Whether the current user is granted the role 'Manager'
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isManager = computed(() => isGranted(UserView.ROLE_MANAGER, user.value));

    /**
     * Whether the current user is granted the role 'Admin'
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isAdmin = computed(() => isGranted(UserView.ROLE_ADMIN, user.value));

    /**
     * Whether the current user is granted the role 'SuperAdmin'
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isSuperAdmin = computed(() => isGranted(UserView.ROLE_SUPERADMIN, user.value));

    const isManagerForbiddenToModifyRoles = computed(() => isManager.value && !isAdmin.value);

    const {hasValidToken} = useTokenAuthenticationState();

    const accountStore = useAccountStore();

    /**
     * Whether the current user is considered authenticated
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isAuthenticated = computed(
        () => hasValidToken.value && !!user.value && !!accountStore.account
    );

    /**
     * Whether the current user is assigned a license and their account subscription isn't expired
     * @type {import('vue').ComputedRef<boolean>}
     */
    const hasActiveLicense = computed(
        () => user.value?.licenseActive && accountStore.account?.subscription?.type !== 'Expired'
    );

    /**
     * Whether the current user is allowed to delete seating elements like location, floor, room
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isAllowedToDeleteSeatingElements = computed(() => {
        if (isSuperAdmin.value) {
            return true;
        } else if (isAdmin.value) {
            return true;
        } else if (isManager.value) {
            return accountStore.account?.managersDeletingSeatingElements;
        } else {
            return false;
        }
    });

    useAuthenticationStateChangedRedirect(isAuthenticated);

    return {
        user: readonlyUser,
        isAnalyst,
        isManager,
        isAdmin,
        isSuperAdmin,
        isManagerForbiddenToModifyRoles,
        isAuthenticated,
        hasActiveLicense,
        isAllowedToDeleteSeatingElements,
        updateUser,
    };
});

function useTokenAuthenticationState() {
    const securityStore = useSecurityStore();
    const oidcStore = useOidcStore();

    /**
     * @type {import('vue').ComputedRef<boolean>}
     */
    const hasValidToken = computed(
        () => securityStore.isRefreshingToken || !!securityStore.token || oidcStore.hasOidcToken
    );

    return {hasValidToken};
}

/**
 * Sets up redirect to the login page when `isAuthenticated` changes.
 * @param {import('vue').Ref} isAuthenticated
 */
function useAuthenticationStateChangedRedirect(isAuthenticated) {
    const router = useRouter();

    watch(isAuthenticated, (currentValue, previousValue) => {
        if (!currentValue && previousValue) {
            void router.push({name: 'Login'});
        }
    });
}
