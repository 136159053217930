export default {
    root: ({props, state, parent}) => ({
        class: [
            // Display and Position
            'inline-flex',
            'relative',
            'h-[38px] w-full',
            // Shape
            {'rounded-md': parent.instance.$name !== 'InputGroup'},
            {
                'first:rounded-l-md rounded-none last:rounded-r-md':
                    parent.instance.$name == 'InputGroup',
            },
            {'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup'},
            {'first:ml-0 ml-[-1px]': parent.instance.$name == 'InputGroup' && !props.showButtons},
            // Color and Background
            {'bg-white': !props.disabled},
            'border',
            {'border-zinc-300': !props.invalid},
            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            {'border-red-500': props.invalid},
            // Transitions
            'transition-all',
            'duration-200',
            // States
            {'hover:border-primary-hover': !props.invalid},
            {
                'outline-none outline-offset-0 ring-1 ring-primary z-10': state.focused,
            },
            // Misc
            'cursor-pointer',
            'select-none',
            {
                'bg-zinc-50 select-none pointer-events-none cursor-default': props.disabled,
            },
        ],
    }),
    label: ({props}) => {
        return {
            class: [
                //Font
                'leading-[normal]',
                // Display
                'block',
                'flex-auto',
                // Color and Background
                'bg-transparent',
                'border-0',
                {
                    'text-zinc-700': props.modelValue != null,
                    'text-zinc-500': props.modelValue == null,
                },
                'placeholder:text-zinc-500',
                // Sizing and Spacing
                'w-[1%]',
                'py-2 px-3',
                {'pr-7': props.showClear},
                //Shape
                'rounded-none',
                // Transitions
                'transition',
                'duration-200',
                // States
                'focus:outline-none focus:shadow-none',
                // Misc
                'relative',
                'cursor-pointer',
                'overflow-hidden overflow-ellipsis',
                'whitespace-nowrap',
                'appearance-none',
            ],
        };
    },
    dropdown: {
        class: [
            'flex items-center justify-center',
            'shrink-0',
            'bg-transparent',
            'text-zinc-500',
            'w-12',
            'rounded-r-md',
        ],
    },
    overlay: {
        class: ['bg-white', 'text-zinc-500', 'border border-zinc-300', 'rounded-md', 'shadow-md'],
    },
    listContainer: {
        class: ['max-h-[200px]', 'overflow-auto'],
    },
    list: {
        class: 'p-1 list-none m-0',
    },
    option: ({context}) => ({
        class: [
            'relative',
            'flex items-center',
            // Font
            'leading-none',
            // Spacing
            'm-0 px-3 py-2',
            'first:mt-0 mt-[2px]',
            // Shape
            'border-0 rounded',
            // Colors
            {
                'text-zinc-500':
                    (!context.focused && !context.selected) ||
                    (context.focused && !context.selected),
                'border-primary-200': context.focused && !context.selected,
                'bg-primary-highlight': context.selected,
            },
            //States
            {
                'hover:bg-zinc-100': !context.focused && !context.selected,
            },
            {'hover:bg-zinc-100': context.selected},
            {
                'hover:text-zinc-500 hover:bg-zinc-100': context.focused && !context.selected,
            },
            // Transition
            'transition-shadow duration-200',
            // Misc
            'cursor-pointer overflow-hidden whitespace-nowrap',
        ],
    }),
    optionGroup: {
        class: ['font-semibold', 'm-0 py-2 px-3', 'text-zinc-500', 'cursor-auto'],
    },
    optionCheckIcon: 'relative -ms-1.5 me-1.5 text-zinc-500',
    optionBlankIcon: 'w-4 h-4',
    emptyMessage: {
        class: ['leading-none', 'py-2 px-3', 'text-zinc-700', 'bg-transparent'],
    },
    header: {
        class: [
            'py-2 px-4',
            'm-0',
            'border-b',
            'rounded-tl-md',
            'rounded-tr-md',
            'text-zinc-700',
            'bg-zinc-50',
            'border-zinc-300',
        ],
    },
    pcfilter: {
        root: () => {
            return {
                class: [
                    'border-zinc-300 border rounded-md h-8',
                    'pr-8 py-2 pl-3',
                    'focus:ring-primary focus:ring-1 focus:outline-none focus:outline-offset-0',
                ],
            };
        },
    },
    pcfiltericoncontainer: {
        root: () => {
            return {class: ['absolute text-zinc-500 top-[17px] right-7']};
        },
    },
    clearIcon: {
        class: ['text-zinc-500', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
    },
    loadingIcon: {
        class: 'text-zinc-500 animate-spin',
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
    },
};
