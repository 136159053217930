import toasteventbus from 'primevue/toasteventbus';

/**
 * @typedef {{label: string, route?: import('vue-router').RouteLocationRaw, url?: string, callbackFn?: function}} ToastLink
 * @typedef {import('primevue/toast').ToastMessageOptions | {customData: {date?: Date, link?: ToastLink}}} CustomMessageOptions
 */

export function useToast() {
    /**
     *
     * @param { "success" | "info" | "warn" | "error" | "secondary" | "contrast"} severity
     * @param {string} message
     * @param {CustomMessageOptions} [options]
     */
    function addSimpleToast(severity, message, options = {}) {
        toasteventbus.emit('add', {severity, summary: message, life: 6000, ...options});
    }

    /**
     *
     * @param {string} message
     * @param {CustomMessageOptions} [options]
     */
    function info(message, options = {}) {
        return addSimpleToast('info', message, options);
    }

    /**
     *
     * @param {string} message
     * @param {CustomMessageOptions} [options]
     */
    function warning(message, options = {}) {
        return addSimpleToast('warn', message, options);
    }

    /**
     *
     * @param {string} message
     * @param {CustomMessageOptions} [options]
     */
    function success(message, options = {}) {
        return addSimpleToast('success', message, options);
    }

    /**
     *
     * @param {string} message
     * @param {CustomMessageOptions} [options]
     */
    function error(message, options = {life: undefined}) {
        return addSimpleToast('error', message, {life: undefined, ...options});
    }

    return {
        /**
         * @inheritDoc
         */
        info,
        /**
         * @inheritDoc
         */
        warning,
        /**
         * @inheritDoc
         */
        success,
        /**
         * @inheritDoc
         */
        error,
    };
}
