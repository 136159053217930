import {defineStore} from 'pinia';
import {useOidcStore} from './oidcStore';
import {ref, toRef} from 'vue';
import {useLocalStorageWithCookies} from '../shared/composables/useLocalStorageWithCookie';
import UnauthenticatedError from '../api/helpers/UnauthenticatedError';
import SecurityRepository from '../api/repositories/SecurityRepository';
import {useInformationStore} from './informationStore';

export const useSecurityStore = defineStore('security', () => {
    /**
     * Standard authentication token
     * @type {import('vue').WritableComputedRef<string | null>}
     */
    const token = useLocalStorageWithCookies('security.token');

    /**
     * Standard authentication refreshToken
     * @type {import('vue').WritableComputedRef<string | null>}
     */
    const refreshToken = useLocalStorageWithCookies('security.refreshToken');

    /**
     * The authentication method, when standard authentication isn't used.
     * @type {import('vue').WritableComputedRef<string | null>}
     */
    const authType = useLocalStorageWithCookies('security.authType');

    /**
     * Whether MSAL is used.
     * @type {import('vue').WritableComputedRef<boolean>}
     */
    const msal = useLocalStorageWithCookies('security.msal', false);

    /**
     * Whether MSAL is blocked.
     * @type {import('vue').WritableComputedRef<boolean>}
     */
    const blockMsal = useLocalStorageWithCookies('security.block_msal', false);
    const msalHandled = useLocalStorageWithCookies('security.msal_handled', false);

    const oidcStore = useOidcStore();

    /**
     * Clears all security related data.
     */
    function reset() {
        token.value = null;
        refreshToken.value = null;
        authType.value = null;
        msal.value = false;
        blockMsal.value = false;
        msalHandled.value = false;
    }

    /**
     * Clears token and OIDC tokens.
     * @param withRefresh When set, also clears the refreshToken
     */
    function clearToken(withRefresh = false) {
        oidcStore.clearOidc();
        token.value = null;
        authType.value = null;

        if (withRefresh) {
            refreshToken.value = null;
        }
    }

    const {refreshAccessToken, isRefreshingToken} = useRefreshAccessToken(
        token,
        refreshToken,
        toRef(oidcStore.hasOidcToken),
        clearToken
    );

    return {
        token,
        refreshToken,
        authType,
        msal,
        blockMsal,
        msalHandled,
        isRefreshingToken,
        clearToken,
        reset,
        refreshAccessToken,
    };
});

function useRefreshAccessToken(token, refreshToken, hasOidcToken, clearToken) {
    const isRefreshingToken = ref(false);

    /**
     *
     * @param shouldClearTokens Whether access tokens should be cleared before refreshing
     * @returns {Promise<boolean>} Promise that resolves with `true` when a new access token was obtained, `false` otherwise
     * @throws UnauthenticatedError When the refresh failed
     */
    async function refreshAccessToken(shouldClearTokens = false) {
        if (isRefreshingToken.value) {
            return false;
        }

        if (!shouldClearTokens && (token.value || hasOidcToken.value)) {
            await useInformationStore().refresh();
            return false;
        }

        isRefreshingToken.value = true;

        if (shouldClearTokens) {
            clearToken();
        }

        try {
            if (!refreshToken.value) {
                throw new UnauthenticatedError();
            }

            const value = await SecurityRepository().refresh(refreshToken.value);

            token.value = value.token;
            refreshToken.value = value.refreshToken;
        } finally {
            isRefreshingToken.value = false;
        }

        return true;
    }

    return {
        refreshAccessToken,
        isRefreshingToken,
    };
}
