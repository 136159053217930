import dayjs, {type Dayjs, isDayjs} from 'dayjs';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export type DateInput = dayjs.Dayjs | Date | string;

/**
 * Returns a dayjs object from any valid dayjs input
 * @param date
 */
export function normalizeDate(date: DateInput) {
    if (isDayjs(date)) {
        return date;
    }

    return dayjs(date);
}

/**
 * Formats the date and time using the current locale.
 * @example
 * de: 22.08.2024 14:48
 * en: 08/22/2024 2:48 PM
 * @param date
 */
export function getFormattedDateAndTime(date: DateInput) {
    const normalized = normalizeDate(date);

    return normalized.format('L LT');
}

/**
 * Formats the date using the current locale.
 * @example
 * de: 22.08.2024
 * en: 08/22/2024
 */
export function getFormattedDate(date: DateInput) {
    const normalized = normalizeDate(date);

    return normalized.format('L');
}

/**
 * Returns a date in the specified timezone (or the guessed timezone of the user if not passed)
 * that represents the same date and time as the input date.
 * @example
 * toLocaleDateTime('2024-08-22T14:37:13.729Z', 'Europe/Berlin').toDate()
 * // returns 'Thu Aug 22 2024 14:37:13 GMT+0200 (Mitteleuropäische Sommerzeit)'
 */
export function toLocalDateTime(date: DateInput, timeZone: string | null = null) {
    timeZone ??= dayjs.tz.guess();
    if (!date) {
        return null;
    }
    return dayjs.tz(date, timeZone);
}

/**
 * Compares two dates using the dayjs.isSame function.
 * @example
 * isSame('24-08-22T12:00', '24-08-22T16:00', 'day')
 * // returns true
 */
export function isSame(date1: DateInput, date2: DateInput, unit: dayjs.OpUnitType): boolean {
    if (date1 === date2) {
        return true;
    }
    return normalizeDate(date1).isSame(normalizeDate(date2), unit);
}

/**
 * Returns a function that can be used within {@link Array#sort} to sort by date.
 * @param dateSelector A selector function that returns the date property which should be
 * sorted by.
 * @param sortOrder
 */
export function getDateSortCompareFn<T>(
    dateSelector: (item: T) => DateInput,
    sortOrder: 'asc' | 'desc' = 'asc'
) {
    const getDate = (item: T) => normalizeDate(dateSelector(item));

    switch (sortOrder) {
        case 'asc':
            return (left: T, right: T) => getDate(left).unix() - getDate(right).unix();
        case 'desc':
            return (left: T, right: T) => getDate(right).unix() - getDate(left).unix();
    }
}

export const weekdaysShort = () => dayjs.weekdaysShort();

export function parseTime(str: string) {
    if (str.length != 5) {
        return;
    }

    const hours = parseInt(str.substring(0, 2));
    const minutes = parseInt(str.substring(3, 6));

    if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        return;
    }

    if (hours < 0 || hours > 24) {
        return;
    }

    if (minutes < 0 || minutes > 60) {
        return;
    }

    return {minutes, hours};
}

/**
 * Updates the passed {@param date} with the parsed time components of {@param time}.
 * If the time component can't be parsed to a valid time, the date will be returned unchanged.
 * @param date The date to update
 * @param time The time in the format 'HH:mm' (ex: '08:00')
 */
export function updateTimeComponents(date: Dayjs, time: string): Dayjs {
    const result = parseTime(time);
    if (result) {
        return date
            .set('hours', result.hours)
            .set('minutes', result.minutes)
            .set('milliseconds', 0);
    }
    return date;
}
