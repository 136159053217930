import SelectView from './SelectView.js';

export default class TeamFilterView {
    constructor(json) {
        this.departmentName = json.departmentName;
        this.companyName = json.companyName;
        this.options = [];

        json.options?.forEach(option => {
            this.options.push(new SelectView(option));
        });
    }
}
