<template>
    <div class="flex size-full flex-col justify-stretch">
        <toast-portal />
        <router-view />
        <rate-limiting-modal />
    </div>
</template>

<script setup lang="ts">
import whiteLabelHelper from './api/helpers/whiteLabelHelper.js';
import {useAsyncState, useEventBus, whenever} from '@vueuse/core';
import {storeToRefs} from 'pinia';
import {useAccountStore} from './stores/accountStore';
import {useGlobalStore} from './stores/globalStore';
import ToastPortal from './shared/components/toast/ToastPortal.vue';
import {useScriptLoader} from './shared/composables/useScriptLoader';
import {useRoute, useRouter} from 'vue-router';
import store from './store';
import {watch} from 'vue';
import RateLimitingModal from './shared/components/RateLimitingModal.vue';

whiteLabelHelper.methods.setGlobalColors();
whiteLabelHelper.methods.setDefaultColors();

useGlobalStore().init();
useScriptLoader();

const router = useRouter();
const route = useRoute();

const {account} = storeToRefs(useAccountStore());

const {state: isReady} = useAsyncState(() => router.isReady().then(() => true), false);

whenever(isReady, () => {
    let resourceType = 'seat';
    if (typeof route.name === 'string') {
        if (route.name.toLowerCase().includes('zone')) {
            resourceType = 'zone';
        }

        if (route.name.toLowerCase().includes('meetingroom')) {
            resourceType = 'meetingRoom';
        }
    }

    store.dispatch('resources/selectResource', resourceType);
});

watch(account, accountValue => {
    whiteLabelHelper.methods.whiteLabelCheck(accountValue);
    initializeResourceTypes();
});

initializeResourceTypes();

function initializeResourceTypes() {
    useEventBus('resourceTypesInitialized');
    store
        .dispatch('resources/initializeResourceTypes', {
            accountInformation: account.value,
            currentRouteName: '',
        })
        .then(() => {
            useEventBus('resourceTypesInitialized').emit();
        });
}
</script>
