export default class SubscriptionView {
    constructor(json) {
        this.active = json['active'];
        this.assignAutomatically = json['assignAutomatically'];
        this.countLegacyUsers = json['countLegacyUsers'];
        this.countLicenses = json['countLicenses'];
        this.id = json['id'];
        this.paymentDate = json['paymentDate'];
        this.portalUrl = json['portalUrl'];
        this.stripeSubscriptionId = json['stripeSubscriptionId'];
        this.stripeSubscriptionPriceId = json['stripeSubscriptionPriceId'];
        this.trialEndDate = json['trialEndDate'];
        this.trialEndDays = json['trialEndDays'];
        this.type = json['type'];
        this.usedLicenses = json['usedLicenses'];
        this.stripeCustomerId = json['stripeCustomerId'];
    }
}
