export default {
    methods: {
        /**
         *
         * @param resourceKey key for desired string modification
         * @example
         * // seat => Seat
         */
        resourceKeyToFirstUpperChar(resourceKey) {
            if (!resourceKey) {
                return;
            }

            return resourceKey.charAt(0).toUpperCase() + resourceKey.slice(1);
        },
        /**
         *
         * @param resourceKey key for desired filter fetch string
         * @returns {string} filter fetch string
         * @example
         * // 'filterSeat/fetchSeatList'
         */
        getResourceListDispatchString(resourceKey) {
            const modifiedKey = this.resourceKeyToFirstUpperChar(resourceKey);
            return `filter${modifiedKey}/fetch${modifiedKey}List`;
        },
        /**
         *
         * @param resourceKey key for desired filter fetch string
         * @returns {string} filter fetch string
         * @example
         * // 'filterSeat/resetSeat'
         */
        getResourceResetDispatchString(resourceKey) {
            const modifiedKey = this.resourceKeyToFirstUpperChar(resourceKey);
            return `filter${modifiedKey}/reset${modifiedKey}`;
        },
    },
};
