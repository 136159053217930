import {useUserStore} from '../../stores/userStore';
import {isGranted} from '../../shared/mixins/security';
import {useToast} from '../../shared/components/toast/useToast.js';
import {translate} from '../../shared/mixins/translation';
import {useUserFeatureFlagStore} from '../../stores/userFeatureFlagStore';
import {debug} from '../../shared/helpers/debug';

const metaHandling = {
    // check if user has demanded role
    role: ({value}) => {
        const {isAuthenticated, user} = useUserStore();
        if (!isAuthenticated) {
            return;
        }

        if (!isGranted(value, user)) {
            useToast().error(translate('app.errors.not_authorized'));
            return {name: 'Home'};
        }
    },
    // checks for public routes
    public: () => {
        const query = {};

        const {isAuthenticated} = useUserStore();
        // prevent logged-in user from accessing public pages
        if (isAuthenticated) {
            debug.log('public target but logged in');
            return {name: 'Home', query};
        }
    },
    //check if route has feature restriction
    featureFlag: async ({value, from}) => {
        const featureFlagStore = useUserFeatureFlagStore();
        await featureFlagStore.ensureIsLoaded();

        for (const [key] of Object.entries(featureFlagStore.userFlags).filter(
            ([, flagValue]) => !flagValue
        )) {
            if (value !== key) {
                continue;
            }

            useToast().error(translate('app.errors.feature_flags.restriction'));

            const query = {};

            if (from.query.inMSTeams) {
                query.inMSTeams = from.query.inMSTeams;
                query.teamsType = from.query.teamsType;
            }

            return {name: 'Home', query};
        }
    },
};

/**
 *
 * @param {RouteLocationNormalized} to
 * @param {RouteLocationNormalized} from
 * @return {NavigationGuardResult}
 */
export async function handleMetaProperties(to, from) {
    const {inMSTeams, teamsType} = from.query;
    const query = {inMSTeams, teamsType};

    // loop through meta tags
    debug.start('feature flag handling');
    for (const flag in metaHandling) {
        debug.log(`handle flag: ${flag}`);
        if (!to.meta[flag]) {
            debug.log('continue');

            continue;
        }

        let result;

        try {
            // execute custom method
            result = await Promise.resolve(
                metaHandling[flag]({
                    value: to.meta[flag],
                    to,
                    from,
                })
            );
        } catch {
            debug.log('exception');
            debug.end('feature flag handling');

            // route to login on UnauthenticatedError
            return {name: 'Login', query};
        }

        // continue if no custom handling is required
        if (!result) {
            debug.log('result empty');
            continue;
        }

        // forward to target component returned by handler
        debug.log('flag passed');
        debug.end('feature flag handling');

        return result;
    }
    debug.end('feature flag handling');
}
