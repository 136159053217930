import {defineStore} from 'pinia';
import {computedAsync, until, useMemoize} from '@vueuse/core';
import FeatureFlagsRepository from '../api/repositories/FeatureFlagsRepository';
import UserFeatures from '../api/models/view/UserFeatures';
import {useUserStore} from './userStore';
import {ref} from 'vue';
import {useInformationStore} from './informationStore';

export const useUserFeatureFlagStore = defineStore('userFeatureFlag', () => {
    const userStore = useUserStore();
    const informationStore = useInformationStore();

    const getFeatureFlagsMemoized = useMemoize(() => FeatureFlagsRepository().browseUserFeatures());

    const loading = ref(false);

    /**
     * Holds the user feature flags when the user is authenticated and the flags are fully loaded, otherwise null.
     * @type {import('vue').Ref<UserFeatures|null|undefined>}
     */
    const userFlags = computedAsync(
        async () => {
            if (!userStore.isAuthenticated || !informationStore.isInitialized) {
                return new UserFeatures({});
            }
            try {
                return await getFeatureFlagsMemoized(userStore.user?.id);
            } catch {
                return new UserFeatures({});
            }
        },
        null,
        {evaluating: loading}
    );

    /**
     * A promise that resolves when the userFlags aren't loading.
     * @returns {Promise<boolean>}
     */
    function ensureIsLoaded() {
        return until(loading).toBe(false);
    }

    return {userFlags, loading, ensureIsLoaded};
});
