const UPDATE_AVAILABLE_FLOORS = 'UPDATE_AVAILABLE_FLOORS';

const state = () => ({
    availableFloors: [],
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} floors list of floors in location
     */
    updateAvailableFloors({commit}, floors) {
        commit(UPDATE_AVAILABLE_FLOORS, floors);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Array} floors list of floors in location
     */
    [UPDATE_AVAILABLE_FLOORS](state, floors) {
        state.availableFloors = floors;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
