import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import {useLocationsStore} from './locationsStore';
import {useAccountStore} from './accountStore';
import {useUserStore} from './userStore';
import SecurityRepository from '../api/repositories/SecurityRepository';
import {useLocaleStore} from './localeStore';

export const useInformationStore = defineStore('information', () => {
    const information = ref(null);

    const isInitialized = ref(false);

    async function refresh(withAccount = null) {
        const informationResult = await SecurityRepository().refreshData(withAccount);

        isInitialized.value = true;

        if (!informationResult) {
            return false;
        }

        information.value = informationResult;

        return true;
    }

    function reset() {
        isInitialized.value = false;
    }

    useUpdateDependentData(information);

    return {information, isInitialized, reset, refresh};
});

/**
 *
 * @param{import('vue').Ref<Object>} information
 */
function useUpdateDependentData(information) {
    const {updateLocations} = useLocationsStore();
    const {updateAccount} = useAccountStore();
    const {updateUser} = useUserStore();
    const {updateLocale} = useLocaleStore();

    watch(information, value => {
        if (!value) {
            return;
        }
        const {availableLocations, accountInformation, user, currentLocale} = value;
        updateLocations(availableLocations);
        updateAccount(accountInformation);
        updateUser(user);
        updateLocale(currentLocale);
    });
}
