export default {
    methods: {
        createFilterUrl(url, location, room, characteristics, days, timeframe, from, until) {
            const searchParams = new URLSearchParams();
            if (location) {
                searchParams.append('location', location);
            }

            if (room) {
                searchParams.append('room', room);
            }

            if (characteristics) {
                characteristics.forEach(characteristic => {
                    searchParams.append('characteristics[]', characteristic);
                });
            }

            if (days?.length) {
                days.forEach(day => {
                    searchParams.append('dates[]', day);
                });
            }

            if (timeframe) {
                searchParams.append('timeframe', timeframe);
            }

            if (from) {
                searchParams.append('from', from);
            }

            if (until) {
                searchParams.append('until', until);
            }

            return `${url}?${searchParams.toString()}`;
        },
    },
};
