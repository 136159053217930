import MeetingRoomRepository from '../../api/repositories/MeetingRoomRepository';

const SAVE_MEETINGROOMLIST = 'SAVE_MEETINGROOMLIST',
    SAVE_OCCUPIED_MEETINGROOMLIST = 'SAVE_OCCUPIED_MEETINGROOMLIST',
    SELECT_MEETINGROOM = 'SELECT_MEETINGROOM',
    RESET_MEETINGROOM = 'RESET_MEETINGROOM',
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    RESET_MEETINGROOMLIST = 'RESET_MEETINGROOMLIST';

const state = () => ({
    filteredMeetingRooms: [],
    roomAvailableMeetingRooms: [],
    occupiedMeetingRooms: [],
    selectedMeetingRoom: null,
    meetingRoomsLoading: false,
});

const actions = {
    /**
     *
     * @param {Object} rootState access state from other module
     * @param {function} commit commits mutation
     */
    fetchMeetingRoomList({rootState, commit}) {
        commit(SET_LOADING_STATE, true);

        MeetingRoomRepository()
            .filter(
                rootState.filterLocation.selectedLocation,
                rootState.filterRoom.selectedRoom,
                rootState.filterCharacteristic.selectedCharacteristics,
                rootState.filterDay.selectedDays,
                rootState.filterTimeframe.selectedTimeframe,
                rootState.filterTime.from,
                rootState.filterTime.until
            )
            .then(filteredMeetingRooms => {
                commit(SAVE_MEETINGROOMLIST, filteredMeetingRooms);

                MeetingRoomRepository()
                    .filterOccupied(
                        rootState.filterLocation.selectedLocation,
                        rootState.filterRoom.selectedRoom,
                        rootState.filterCharacteristic.selectedCharacteristics,
                        rootState.filterDay.selectedDays,
                        rootState.filterTimeframe.selectedTimeframe,
                        rootState.filterTime.from,
                        rootState.filterTime.until
                    )
                    .then(occupiedMeetingRoomList => {
                        commit(SAVE_OCCUPIED_MEETINGROOMLIST, occupiedMeetingRoomList);
                        commit(SET_LOADING_STATE, false);
                    });
            });
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {String} meetingRoom id of meetingRoom to select
     */
    selectMeetingRoom({commit}, meetingRoom) {
        commit(SELECT_MEETINGROOM, meetingRoom);
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    resetMeetingRoom({commit}) {
        commit(RESET_MEETINGROOM);
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    resetMeetingRoomList({commit}) {
        commit(RESET_MEETINGROOMLIST);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {MeetingRoomFilterView} filteredMeetingRooms filter results
     */
    [SAVE_MEETINGROOMLIST](state, filteredMeetingRooms) {
        state.filteredMeetingRooms = filteredMeetingRooms.meetingRooms;
        state.roomAvailableMeetingRooms = filteredMeetingRooms.roomAvailableMeetingRooms;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} occupiedMeetingRoomList list of occupied meetingRooms by filter
     */
    [SAVE_OCCUPIED_MEETINGROOMLIST](state, occupiedMeetingRoomList) {
        state.occupiedMeetingRooms = occupiedMeetingRoomList;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} meetingRoom id of meetingRoom to select
     */
    [SELECT_MEETINGROOM](state, meetingRoom) {
        state.selectedMeetingRoom = meetingRoom;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_MEETINGROOM](state) {
        state.selectedMeetingRoom = null;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_MEETINGROOMLIST](state) {
        state.filteredMeetingRooms = [];
        state.roomAvailableMeetingRooms = [];
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Boolean} loadingState state of fetch
     */
    [SET_LOADING_STATE](state, loadingState) {
        state.meetingRoomsLoading = loadingState;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
