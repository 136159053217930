import filterHelper from '../helper/filterHelper';

const UPDATE_AVAILABLE_CHARACTERISTICS = 'UPDATE_AVAILABLE_CHARACTERISTICS',
    SELECT_CHARACTERISTIC = 'SELECT_CHARACTERISTIC',
    DESELECT_CHARACTERISTIC = 'DESELECT_CHARACTERISTIC',
    RESET = 'RESET';

const state = () => ({
    selectedCharacteristics: [],
    availableCharacteristics: null,
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} characteristics list of characteristics in location
     */
    updateAvailableCharacteristics({commit}, characteristics) {
        commit(UPDATE_AVAILABLE_CHARACTERISTICS, characteristics);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {function} dispatch dispatch another action
     * @param {Object} rootState access state from other module
     * @param {string} characteristic id of characteristic to be toggled
     */
    toggleCharacteristic({commit, state, dispatch, rootState}, characteristic) {
        const modifiedResourceType = filterHelper.methods.resourceKeyToFirstUpperChar(
            rootState.resources.resourceTypes.find(({selected}) => selected)?.key
        );

        dispatch(`filter${modifiedResourceType}/reset${modifiedResourceType}`, {}, {root: true});

        if (state.selectedCharacteristics.includes(characteristic)) {
            commit(DESELECT_CHARACTERISTIC, characteristic);
            dispatch(
                `filter${modifiedResourceType}/fetch${modifiedResourceType}List`,
                {},
                {root: true}
            );
            return;
        }

        commit(SELECT_CHARACTERISTIC, characteristic);
        dispatch(
            `filter${modifiedResourceType}/fetch${modifiedResourceType}List`,
            {},
            {root: true}
        );
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch dispatch another action
     * @param {Object} rootState access state from other module
     * @param {Boolean} fetchResourceList calls resourceList fetch
     */
    reset({commit, dispatch, rootState}, fetchResourceList = false) {
        commit(RESET);

        const modifiedResourceType = filterHelper.methods.resourceKeyToFirstUpperChar(
            rootState.resources.resourceTypes.find(({selected}) => selected)?.key
        );

        if (fetchResourceList) {
            dispatch(
                `filter${modifiedResourceType}/fetch${modifiedResourceType}List`,
                {},
                {root: true}
            );
        }
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Array} characteristics list of characteristics in location
     */
    [UPDATE_AVAILABLE_CHARACTERISTICS](state, characteristics) {
        state.availableCharacteristics = characteristics;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} characteristic id of characteristic to be selected
     */
    [SELECT_CHARACTERISTIC](state, characteristic) {
        state.selectedCharacteristics.push(characteristic);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} characteristicToRemove id of characteristic to be deselected
     */
    [DESELECT_CHARACTERISTIC](state, characteristicToRemove) {
        state.selectedCharacteristics = state.selectedCharacteristics.filter(
            characteristic => characteristic !== characteristicToRemove
        );
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET](state) {
        state.selectedCharacteristics = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
