import {translate} from '../../../shared/mixins/translation';
import CharacteristicView from './CharacteristicView';
import AuthorizationGroup from '../form/AuthorizationGroup.js';

export default class ZoneView {
    constructor(json) {
        this.id = json.id;
        this.name = json.name ?? '';
        this.number = json.number;
        this.defaultName = translate('app.administration.zone.form.name.default_name', {
            '%number%': this.number,
        });
        this.dimensions =
            typeof json.dimensions === 'string' ? JSON.parse(json.dimensions) : json.dimensions;
        this.availableCapacity = json.availableCapacity ?? 0;
        this.locationId = json.locationId;
        this.floorName = json.floorName;
        this.roomName = json.roomName;
        this.room = json.room;
        this.maxCapacity = json.maxCapacity;
        this.location = json.location;
        this.locationName = json.locationName;
        this.blocked = json.blocked;
        this.locationId = json.locationId;
        this.characteristics =
            json.characteristics?.map(characteristic => new CharacteristicView(characteristic)) ??
            [];

        this.authorizationGroups =
            json.authorizationGroups?.map(
                authorizationGroup =>
                    new AuthorizationGroup(
                        authorizationGroup.space,
                        authorizationGroup.company,
                        authorizationGroup.departments,
                        authorizationGroup.teams,
                        authorizationGroup.users
                    )
            ) ?? [];

        this.isFavorite = false;
    }

    static createEmpty() {
        return new ZoneView({
            id: '',
            number: -1,
            name: '',
            defaultName: '',
            dimensions: {},
            availableCapacity: -1,
            maxCapacity: -1,
            blocked: false,
            characteristics: [],
            floorName: '',
            locationName: '',
            locationId: '',
            room: '',
            roomName: '',
            authorizationGroups: [],
            calendarResourceId: '',
            isFavorite: false,
        });
    }
}
