import FilteredUserDayBookingsView from './FilteredUserDayBookingsView';

export default class WeekOverviewUserDetailsView {
    constructor(json) {
        this.allowedToDelete = json.allowedToDelete;
        this.dayBookingList = json.dayBookingDetailsList.map(dayBookingsArray =>
            dayBookingsArray.dayBookings.map(
                dayBookings => new FilteredUserDayBookingsView(dayBookings)
            )
        );
    }
}
