export default class TimeframeView {
    constructor(json) {
        this.id = json['id'];
        this.name = json['name'];
        this.active = json['active'];
        this.from = json['from'];
        this.until = json['until'];
        this.location = json['location'];
    }
}
