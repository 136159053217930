import {useToast} from '../../shared/components/toast/useToast.js';
import {translate} from '../../shared/mixins/translation';
import {useUserStore} from '../../stores/userStore';
import {useAccountStore} from '../../stores/accountStore';

/**
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export const requireLicense = to => {
    const {isAuthenticated, user, isAdmin} = useUserStore();
    const {currentAccountHasLicenseType} = useAccountStore();

    if (!isAuthenticated) {
        return {name: 'Login'};
    }

    if (currentAccountHasLicenseType('Expired')) {
        /**
         * # Temporary fix for expired licenses #
         * With this workaround we allow the access for the admin to the licensing components. The other admin routes are also accessible but handeled by the backend.
         * TODO: Remove this once we have dedicated navigation guards for each feature
         */
        if (isAdmin && to.path.includes('/license')) {
            return;
        }

        useToast().error(translate('app.errors.feature_flags.restriction'));
        return {name: 'Home'};
    }

    // special handling for no user license behaviour
    if (!user?.licenseActive) {
        useToast().error(translate('app.errors.feature_flags.restriction'));
        return {name: 'Home'};
    }
};
