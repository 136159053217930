import ZoneRepository from '../../api/repositories/ZoneRepository';

const SAVE_ZONELIST = 'SAVE_ZONELIST',
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SELECT_ZONE = 'SELECT_ZONE',
    RESET_ZONE = 'RESET_ZONE',
    RESET_ZONELIST = 'RESET_ZONELIST';

const state = () => ({
    filteredZones: [],
    roomAvailableZones: [],
    selectedZone: null,
    zonesLoading: false,
    bookedCapacity: 1,
});

const actions = {
    /**
     *
     * @param {Object} rootState access state from other module
     * @param {function} commit commits mutation
     */
    fetchZoneList({rootState, commit}) {
        commit(SET_LOADING_STATE, true);

        ZoneRepository()
            .filter(
                rootState.filterLocation.selectedLocation,
                rootState.filterRoom.selectedRoom,
                rootState.filterCharacteristic.selectedCharacteristics,
                rootState.filterDay.selectedDays,
                rootState.filterTimeframe.selectedTimeframe,
                rootState.filterTime.from,
                rootState.filterTime.until
            )
            .then(zoneList => {
                commit(SAVE_ZONELIST, zoneList);
                commit(SET_LOADING_STATE, false);
            });
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {String} zone id of zone to select
     */
    selectZone({commit}, zone) {
        commit(SELECT_ZONE, zone);
    },
    /**
     * @param {function} commit commits mutation
     */
    resetZone({commit}) {
        commit(RESET_ZONE);
    },
    /**
     * @param {function} commit commits mutation
     */
    resetZoneList({commit}) {
        commit(RESET_ZONELIST);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} zoneList list of filter results
     */
    [SAVE_ZONELIST](state, zoneList) {
        state.filteredZones = zoneList.zones;
        state.roomAvailableZones = zoneList.roomAvailableZones;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Boolean} loadingState state of fetch
     */
    [SET_LOADING_STATE](state, loadingState) {
        state.zonesLoading = loadingState;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} zone id of zone to select
     */
    [SELECT_ZONE](state, zone) {
        state.selectedZone = zone;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_ZONE](state) {
        state.selectedZone = null;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_ZONELIST](state) {
        state.filteredZones = [];
        state.roomAvailableZones = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
