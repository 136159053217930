import {translate} from '../../../shared/mixins/translation';

export default class ResourceView {
    constructor(json) {
        this.id = json.id;
        this.checkIn = json.checkIn;
        this.from = json.from;
        this.until = json.until;
        this.floorName = json.floorName;
        this.locationName = json.locationName;
        this.resourceName = json.resourceName;
        this.resourceNumber = json.resourceNumber;
        this.resourceType = json.resourceType;
        this.locationX = json.locationX;
        this.locationY = json.locationY;
        this.dimensions =
            typeof json.dimensions === 'string' ? JSON.parse(json.dimensions) : json.dimensions;
        this.roomId = json.roomId;
        this.roomName = json.roomName;

        if (json.resourceType === 'seat') {
            this.defaultName = translate('app.seatDetails.seat_name', {
                '%number%': this.resourceNumber,
            });
        } else if (json.resourceType === 'zone') {
            this.defaultName = translate('app.administration.zone.form.name.default_name', {
                '%number%': this.resourceNumber,
            });
        }
    }
}
