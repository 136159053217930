import {translate} from '../../../shared/mixins/translation';

export default class LocationView {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.floors = json.floors || [];
        this.timeframes =
            json.timeframes?.map(timeframe => {
                timeframe.name = translate(timeframe.name);
                return timeframe;
            }) || [];
        this.timezone = json.timezone;
        this.hotDeskingActive = json.hotDeskingActive;
    }
}
