import AccountView from '../view/AccountView';

export default class AccountForm extends AccountView {
    constructor(json) {
        super(json);

        this.name = json.name;
        this.email = json.email;
        this.phoneNumber = json.phoneNumber;
        this.locale = json.locale;
        this.customDomain = json.customDomain;
        this.customColors = json.customColors;
        this.primaryColor = json.primaryColor ?? '';
        this.secondaryColor = json.secondaryColor ?? '';
        this.hoverColor = json.hoverColor ?? '';
        this.automaticallyDeleteBookings = json.automaticallyDeleteBookings;
        this.automaticallyDeleteBookingsTimeSpan = json.automaticallyDeleteBookingsTimeSpan;
    }
}
