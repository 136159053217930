const SAVE_SEARCH_STRING = 'SAVE_SEARCH_STRING',
    SAVE_SELECTED_DAY = 'SAVE_SELECTED_DAY';

const state = () => ({
    searchString: '',
    selectedDay: null,
});

const actions = {
    saveSearchString({commit}, searchString) {
        commit(SAVE_SEARCH_STRING, searchString);
    },
    saveSelectedDay({commit}, selectedDay) {
        commit(SAVE_SELECTED_DAY, selectedDay);
    },
};

const mutations = {
    [SAVE_SEARCH_STRING](state, searchString) {
        state.searchString = searchString;
    },
    [SAVE_SELECTED_DAY](state, selectedDay) {
        state.selectedDay = selectedDay;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
