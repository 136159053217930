import {defineStore} from 'pinia';
import {useOidcStore} from './oidcStore';
import {computed} from 'vue';
import {useSecurityStore} from './securityStore';
import {useImpersonatingStore} from './impersonatingStore';
import {useLocalStorage} from '@vueuse/core';

export const useAuthorizationStore = defineStore('authorization', () => {
    const securityStore = useSecurityStore();
    const oidcStore = useOidcStore();

    const impersonatingStore = useImpersonatingStore();

    /**
     * Headers that should be used to authorize requests of the current user.
     * @type {import('vue').ComputedRef<{Authorization?: string, 'X-AUTH-MODE'?: string}>}
     */
    const authorizationHeaders = computed(() => {
        const headers = {};
        if ((securityStore.token || oidcStore.hasOidcToken) && impersonatingStore.isImpersonating) {
            headers['Authorization'] = `Bearer ${impersonatingStore.impersonatingToken}`;

            return headers;
        }

        if (securityStore.token) {
            headers['Authorization'] = `Bearer ${securityStore.token}`;

            if (securityStore.authType === 'MS-Access-Token' && securityStore.blockMsal) {
                return headers;
            }
            if (securityStore.authType === 'MS-Access-Token') {
                headers['X-AUTH-MODE'] = securityStore.authType;
                return headers;
            }
        }

        if (oidcStore.idToken) {
            headers['Authorization'] = `Bearer ${oidcStore.idToken}`;
            headers['X-AUTH-MODE'] = 'OIDC-ID-Token';
        }

        if (oidcStore.accessToken) {
            headers['Authorization'] = `Bearer ${oidcStore.accessToken}`;
            headers['X-AUTH-MODE'] = 'OIDC-Access-Token';
        }

        return headers;
    });

    const unauthorizedRequestsCount = useLocalStorage('unauthorizedRequestsCount', 0);

    function incrementUnauthorizedRequestsCount() {
        unauthorizedRequestsCount.value = unauthorizedRequestsCount.value + 1;
    }

    function resetUnauthorizedRequestsCount() {
        if (!unauthorizedRequestsCount.value) {
            return;
        }

        unauthorizedRequestsCount.value = 0;
    }

    return {
        authorizationHeaders,
        unauthorizedRequestsCount,
        incrementUnauthorizedRequestsCount,
        resetUnauthorizedRequestsCount,
    };
});
