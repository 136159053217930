export default class FilterRoomView {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.floor = json.floor;
        this.floorName = json.floorName;
        this.locationName = json.locationName;
        this.roomPlanPath = json.roomPlanPath;
        this.roomPlanHeight = json.roomPlanHeight;
        this.roomPlanWidth = json.roomPlanWidth;
        this.itemCount = json.itemCount;
        this.location = json.location;
        this.authorizationGroups = json.authorizationGroups;
        this.hasSeats = json.hasSeats;
        this.hasZones = json.hasZones;
        this.hasMeetingRooms = json.hasMeetingRooms;
    }
}
