<!-- eslint-disable vue/no-deprecated-slot-scope-attribute -->
<template>
    <div slot-scope="props">
        <div :class="`VueTables VueTables--${props.source}`">
            <div :class="props.theme.row">
                <div :class="props.theme.column">
                    <div
                        v-if="
                            !props.opts.filterByColumn &&
                            props.opts.filterable &&
                            props.opts.showSearch
                        "
                        :class="`${props.theme.field} ${props.theme.inline} ${props.theme.left} VueTables__search !m-0`"
                    >
                        <vnodes :vnodes="props.slots.beforeFilter" />
                        <vt-generic-filter ref="genericFilter" />
                        <vnodes :vnodes="props.slots.afterFilter" />
                    </div>
                    <vnodes :vnodes="props.slots.afterFilterWrapper" />

                    <div
                        v-if="props.opts.columnsDropdown"
                        :class="`VueTables__columns-dropdown-wrapper ${props.theme.right} ${props.theme.dropdown.container}`"
                    >
                        <vt-columns-dropdown />
                    </div>
                </div>
            </div>

            <vnodes :vnodes="props.slots.beforeTable" />
            <div class="table-responsive">
                <vt-table ref="vt_table" />
                <observer
                    v-if="props.opts.pagination.virtual && !props.loading"
                    @intersect="props.setPage(props.page + 1)"
                />

                <div class="mt-5 flex items-center justify-between">
                    <div
                        v-if="
                            (props.perPageValues.length > 1 ||
                                props.opts.alwaysShowPerPageSelect) &&
                            !props.opts.pagination.virtual
                        "
                        :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__limit`"
                    >
                        <vnodes :vnodes="props.slots.beforeLimit" />
                        <vt-per-page-selector :total-count="totalCount" />
                        <vnodes :vnodes="props.slots.afterLimit" />
                    </div>

                    <div
                        v-if="props.opts.pagination.dropdown && props.totalPages > 1"
                        class="VueTables__pagination-wrapper"
                    >
                        <div
                            class="flex items-center space-x-5"
                            :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__dropdown-pagination`"
                        >
                            <a
                                v-if="props.page > 1"
                                class="flex items-center text-primary"
                                href="#"
                                @click.prevent="props.setPage(props.page - 1)"
                            >
                                <arrow-icon direction="left" />
                                <span
                                    class="ml-1"
                                    v-text="__('app.administration.pagination.prev')"
                                />
                            </a>

                            <single-select
                                class="mb-3.5 w-48"
                                :options="props.totalPages"
                                :set-select="props.page"
                                @set-selected="e => props.setPage(e)"
                            >
                                <template #option="{option}">
                                    {{
                                        __('app.administration.pagination.option', {
                                            '%page%': option,
                                            '%total%': props.totalPages,
                                        })
                                    }}
                                </template>
                            </single-select>
                            <a
                                v-if="props.page < props.totalPages"
                                class="mb-3.5 flex items-center text-primary"
                                href="#"
                                @click.prevent="props.setPage(props.page + 1)"
                            >
                                <span v-text="__('app.administration.pagination.next')" />
                                <arrow-icon class="ml-2" direction="right" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <vnodes :vnodes="props.slots.afterTable" />

            <vt-pagination v-if="!props.opts.pagination.virtual && props.opts.pagination.show" />
        </div>
    </div>
</template>

<script>
import SingleSelect from '../../../../shared/components/core/SingleSelect.vue';
import ArrowIcon from '../../../../shared/components/icons/ArrowIcon.vue';
import {useEventBus} from '@vueuse/core';
import Observer from 'v-tables-3/compiled/components/Observer';
import VtColumnsDropdown from 'v-tables-3/compiled/components/VtColumnsDropdown';
import VtGenericFilter from 'v-tables-3/compiled/components/VtGenericFilter';
import VtPagination from 'v-tables-3/compiled/components/VtPagination';
import VtPerPageSelector from 'v-tables-3/compiled/components/VtPerPageSelector';
import VtTable from 'v-tables-3/compiled/components/VtTable';
import {h} from 'vue';

export default {
    components: {
        ArrowIcon,
        SingleSelect,
        VtGenericFilter,
        VtPerPageSelector,
        VtColumnsDropdown,
        VtTable,
        VtPagination,
        Observer,
        vnodes: {
            functional: true,
            render: ctx => h(ctx.$attrs.vnodes),
        },
    },
    //eslint-disable-next-line vue/require-prop-types
    props: ['props'],
    data: () => ({
        totalCount: null,
    }),
    created() {
        useEventBus('userTable_count').on(count => {
            this.totalCount = count;
        });
    },
};
</script>
