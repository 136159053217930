import {useTranslationStore} from '../../stores/translationStore';
import {mapState} from 'pinia';

/**
 * @typedef {"messages" | "security" | "valiadtor"} TranslationContext
 */

/**
 * Searches for a value inside the current translations provided be the pinia store matching `transKey`.
 * This function should only be called inside a Vue context.
 *
 * @param {String} transKey They key for which the translations value should be found
 * @param {Array|Object} params Additional parameters that should be replaced inside the found translation.
 * @param {TranslationContext} context Which part of the `translations` object to be searched for
 * @returns {String}
 */
export const translate = (transKey, params = [], context = 'messages') => {
    const {translations} = useTranslationStore();

    return translateWithTranslations(translations, transKey, params, context);
};

/**
 * Searches for a value inside `translations` matching `transKey`.
 *
 * @param {Object} translations The object containing the translations
 * @param {String} transKey They key for which the translations value should be found
 * @param {Array|Object} params Additional parameters that should be replaced inside the found translation.
 * @param {TranslationContext} context Which part of the `translations` object to be searched for
 * @returns {String}
 */
export function translateWithTranslations(
    translations,
    transKey,
    params = [],
    context = 'messages'
) {
    if (!translations || !translations[context] || !translations[context][transKey]) {
        return transKey;
    }

    let trans = translations[context][transKey];

    for (const [key, value] of Object.entries(params)) {
        trans = trans.replace(key, value);
    }

    return trans;
}

export default {
    computed: {
        ...mapState(useTranslationStore, ['translations']),
    },
    methods: {
        __(transKey, params = [], context = 'messages') {
            return translateWithTranslations(this.translations, transKey, params, context);
        },
    },
};
