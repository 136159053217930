export default class AuthenticationView {
    constructor(json) {
        this.count = json.passwordGuidelines.count;
        this.specialCharacter = json.passwordGuidelines.specialCharacters;
        this.upperLowerCase = json.passwordGuidelines.upperLowerCase;
        this.minOneNumber = json.passwordGuidelines.minOneNumber;
        this.audience = json.authenticationSettings.audience;
        this.clientId = json.authenticationSettings.clientId;
        this.configurationUrl = json.authenticationSettings.configurationUrl;
        this.enabled = json.authenticationSettings.enabled;
        this.tenantId = json.authenticationSettings.tenantId;
        this.responseType = json.authenticationSettings.responseType;
        this.limitedLoginAttempts = json.authenticationSettings.limitedLoginAttempts;
        this.singleSignOn = json.authenticationSettings.singleSignOn;
        this.scopes = json.authenticationSettings.scopes;
        this.autoProvisioning = json.authenticationSettings.autoProvisioning;
    }
}
