import {useSecurityStore} from '../../stores/securityStore';

export async function initMsalRedirectLogin(): Promise<void> {
    const securityStore = useSecurityStore();
    securityStore.blockMsal = false;
    securityStore.msal = true;
    securityStore.msalHandled = false;

    const {msalInstance, loginRequest} = await import('./msalAuthConfig');

    const redirectRequest = {...loginRequest, prompt: 'select_account'};

    await msalInstance.loginRedirect(redirectRequest);
}

export async function initMsalSilentLogin(): Promise<void> {
    const securityStore = useSecurityStore();
    const {msalInstance, loginRequest} = await import('./msalAuthConfig');

    const accounts = msalInstance.getAllAccounts();

    const account = msalInstance.getActiveAccount() ?? accounts[0];

    const accessTokenRequest = {
        ...loginRequest,
        account,
    };

    const accessTokenResponse = await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .catch(async error => {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (
                error.errorCode === 'consent_required' ||
                error.errorCode === 'interaction_required' ||
                error.errorCode === 'login_required'
            ) {
                await initMsalRedirectLogin();
            }
        });

    const accessToken = accessTokenResponse?.accessToken;

    if (!accessToken) {
        return;
    }

    // Acquire token silent success
    securityStore.token = accessToken;
    securityStore.authType = 'MS-Access-Token';

    location.reload();
    await new Promise(_ => {});
}
