import {useToast} from '../../shared/components/toast/useToast';
import Api from '../../api/api';
import {translate} from '../../shared/mixins/translation';
import {useSecurityStore} from '../../stores/securityStore';

export const resendSSOVerification = async () => {
    const securityStore = useSecurityStore();

    const isMsal = securityStore.authType === 'MS-Access-Token' && securityStore.msal;

    if (!isMsal) {
        return {name: 'Login'};
    }

    const toast = useToast();

    const response = await Api.get('/api/user/resend-sso-verification');
    if (response.ok) {
        toast.success(translate('app.toast.login.sso.sso_mail_resend_success'));
    } else {
        toast.error(translate('app.toast.login.sso.sso_mail_resend_error'));
    }

    return {name: 'Login'};
};
