import filterHelper from '../helper/filterHelper';
import {useUserStore} from '../../stores/userStore';

const UPDATE_SELECTED_ROOM = 'UPDATE_SELECTED_ROOM',
    UPDATE_AVAILABLE_ROOMS = 'UPDATE_AVAILABLE_ROOMS';

const state = () => ({
    selectedRoom: null,
    availableRooms: [],
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch dispatch another action
     * @param {Object} rootState access state from other module
     * @param {string} room id of room
     */
    selectRoom({commit, dispatch, rootState}, room) {
        commit(UPDATE_SELECTED_ROOM, room);
        const selectedResourceType = rootState.resources.resourceTypes.find(
            ({selected}) => selected
        );
        dispatch(
            filterHelper.methods.getResourceResetDispatchString(selectedResourceType.key),
            {},
            {root: true}
        );
        dispatch(
            filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
            {},
            {root: true}
        );
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} rooms list of rooms in location
     */
    updateAvailableRooms({commit}, rooms) {
        commit(UPDATE_AVAILABLE_ROOMS, rooms);
    },

    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {function} dispatch call other action
     * @param {Object} rootState access state from other module
     * @param {Boolean} fetchSeatList calls seatList fetch
     */
    defaultSelect({commit, state, dispatch, rootState}, fetchSeatList = false) {
        if (!state.availableRooms?.length) {
            return;
        }

        const {user} = useUserStore();

        const primaryRoomId = user?.primaryRoom?.id;

        if (primaryRoomId && state.availableRooms.some(({id}) => id === primaryRoomId)) {
            commit(UPDATE_SELECTED_ROOM, primaryRoomId);
        } else {
            commit(UPDATE_SELECTED_ROOM, state.availableRooms[0].id);
        }

        if (fetchSeatList) {
            //check if resourceTypes exists and is not empty
            const selectedResourceType = rootState.resources.resourceTypes.find(
                ({selected}) => selected
            );

            dispatch(
                filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
                {},
                {root: true}
            );
        }
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {string} room id of room
     */
    [UPDATE_SELECTED_ROOM](state, room) {
        state.selectedRoom = room;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Array} rooms list of rooms in location
     */
    [UPDATE_AVAILABLE_ROOMS](state, rooms) {
        state.availableRooms = rooms;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
