import API from '../api';
import extractFormDataFromObject from '../helpers/FormDataHelper';
import AuthenticationForm from '../models/form/AuthenticationForm';

export const AZURE_DISCOVERY =
    'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration';

export default () => ({
    browse() {
        return API.get('/api/authentication-settings/account')
            .then(API.handle)
            .catch(API.error)
            .then(json => new AuthenticationForm(json));
    },
    edit(authenticationSettings) {
        const formData = new FormData();
        const authentication_settings = {
            type: authenticationSettings.oidcTypeValue,
            enabled: authenticationSettings.enabled,
            configurationUrl: authenticationSettings.configurationUrl,
            clientId: authenticationSettings.clientId,
            clientSecret: authenticationSettings.clientSecret,
            responseType: authenticationSettings.responseType,
            audience: authenticationSettings.audience,
            tenantId: authenticationSettings.tenantId,
            limitedLoginAttempts: authenticationSettings.limitedLoginAttempts,
            singleSignOn: authenticationSettings.singleSignOn,
            scopes: authenticationSettings.scopes,
            autoProvisioning: authenticationSettings.autoProvisioning,
        };
        const password_guideline = {
            count: authenticationSettings.count,
            specialCharacter: authenticationSettings.specialCharacter,
            upperLowerCase: authenticationSettings.upperLowerCase,
            minOneNumber: authenticationSettings.minOneNumber,
        };

        if (authenticationSettings.tenantId) {
            authentication_settings.configurationUrl = AZURE_DISCOVERY;
        }

        const flattenAuthSettings = extractFormDataFromObject(
            authentication_settings,
            'authentication_settings'
        );
        const flattenPasswordGuideLine = extractFormDataFromObject(
            password_guideline,
            'password_guideline'
        );

        for (const pair of flattenAuthSettings.entries()) {
            formData.append(pair[0], pair[1]);
        }

        for (const pair of flattenPasswordGuideLine.entries()) {
            formData.append(pair[0], pair[1]);
        }

        return API.post('/api/admin/authentication-settings/account', formData)
            .then(API.handle)
            .catch(API.error);
    },
    authSettings(subdomain) {
        return fetch(`/api/subdomains/${subdomain}/authorization-settings`, {method: 'GET'})
            .then(API.handle)
            .catch(API.error);
    },
    secret() {
        return API.get('/api/oidc/secret').then(API.handle).catch(API.error);
    },
});
