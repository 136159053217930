import filterHelper from '../helper/filterHelper';

const SELECT_DAY = 'SELECT_DAY',
    DESELECT_DAY = 'DESELECT_DAY',
    RESET = 'RESET';

const state = () => ({
    selectedDays: [],
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {function} dispatch dispatch another action
     * @param {Object} rootState access state from other module
     * @param {String} day string of datetime to select
     */
    toggleDay({commit, state, dispatch, rootState}, day) {
        const selectedResourceType = rootState.resources.resourceTypes.find(
            ({selected}) => selected
        );

        const formattedResourceType = filterHelper.methods.resourceKeyToFirstUpperChar(
            selectedResourceType.key
        );

        //reset selected resource
        dispatch(`filter${formattedResourceType}/reset${formattedResourceType}`, {}, {root: true});

        if (state.selectedDays.includes(day)) {
            commit(DESELECT_DAY, day);
            dispatch(
                filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
                {},
                {root: true}
            );
            return;
        }

        commit(SELECT_DAY, day);
        dispatch(
            filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
            {},
            {root: true}
        );
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch dispatch another action
     * @param {Object} rootState access state from other module
     * @param {Boolean} fetchResourceList calls resourceList fetch
     */
    reset({commit, dispatch, rootState}, fetchResourceList = false) {
        commit(RESET);

        if (fetchResourceList) {
            const selectedResourceType = rootState.resources.resourceTypes.find(
                ({selected}) => selected
            );
            dispatch(
                filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
                {},
                {root: true}
            );
        }
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} day string of datetime to select
     */
    [SELECT_DAY](state, day) {
        state.selectedDays.push(day);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} dayToRemove string of datetime to deselect
     */
    [DESELECT_DAY](state, dayToRemove) {
        state.selectedDays = state.selectedDays.filter(day => day !== dayToRemove);
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET](state) {
        state.selectedDays = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
