<template>
    <svg
        class="h-5 fill-current"
        :class="arrowDirectionClass"
        viewBox="0 0 384 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
            d="M378.8 309.8l-176 165.9C199.7 478.6 195.9 480 192 480s-7.719-1.426-10.77-4.31l-176-165.9C-1.297 303.6-1.781 293.1 4.156 286.3c5.953-6.838 16.09-7.259 22.61-1.134L176 425.9V48.59c0-9.171 7.156-16.59 15.1-16.59S208 39.42 208 48.59v377.3l149.2-140.7c6.516-6.125 16.66-5.704 22.61 1.134C385.8 293.1 385.3 303.6 378.8 309.8z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        direction: {
            type: String,
            required: true,
        },
    },
    computed: {
        arrowDirectionClass() {
            switch (this.direction) {
                case 'up':
                    return ' rotate-180';
                case 'down':
                    return ' rotate-0';
                case 'left':
                    return ' rotate-90';
                case 'right':
                default:
                    return ' rotate-270';
            }
        },
    },
};
</script>
