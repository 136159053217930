import {defineStore} from 'pinia';
import {useLocalStorage} from '@vueuse/core';
import AvailableLocation from '../api/models/view/AvailableLocation';
import {computed, readonly} from 'vue';
import {useStore} from 'vuex';
import {useUserStore} from './userStore';

const locationsSerializer = {
    read: value =>
        value ? JSON.parse(value).map(location => new AvailableLocation(location)) : [],
    write: value => JSON.stringify(value),
};

export const useLocationsStore = defineStore('locations', () => {
    const store = useStore();

    /**
     * All available locations, stored in local storage.
     * @type {import('@vueuse/shared').RemovableRef<AvailableLocation[]>}
     */
    const availableLocations = useLocalStorage('availableLocations', [], {
        serializer: locationsSerializer,
    });

    /**
     * All available locations. Read only.
     * @type {import('vue').Ref<AvailableLocation[]>}
     */
    const readonlyLocations = readonly(availableLocations);

    /**
     * All locations that have seats, zones or meeting rooms.
     * @type {import('vue').ComputedRef<AvailableLocation[]>}
     */
    const filteredLocations = computed(() => {
        if (!availableLocations.value) {
            return [];
        }

        return availableLocations.value.filter(
            ({itemCount: {floorCount, roomCount, seatCount, zoneCount, meetingRoomCount} = {}}) =>
                floorCount && roomCount && (seatCount || zoneCount || meetingRoomCount)
        );
    });

    /**
     * Updates the stored locations.
     * @param {AvailableLocation[]| Array | null} locations Will be mapped to `AvailableLocation[]`
     * @return void
     */
    function updateLocations(locations) {
        if (!locations || !(locations instanceof Array)) {
            // reset selected location to allow new resource-data fetch
            // when navigating to day booking pages
            store.dispatch('filterLocation/resetLocation');
            availableLocations.value = [];
            return;
        }

        availableLocations.value = locations.map(location => {
            if (location instanceof AvailableLocation) {
                return location;
            }

            return new AvailableLocation(location);
        });
    }

    /**
     * Sets a default filter location when none is already set
     * @returns {Promise<void>}
     */
    async function selectDefaultLocation() {
        // check for default location in store
        const filteredLocation = store.state.filterLocation.selectedLocation;

        // if no default location set
        if (!filteredLocation) {
            const {user} = useUserStore();
            // set primary location as default location to initialize booking filters
            // to allow shortcut bookings from home view and week overview
            await store.dispatch(
                'filterLocation/selectLocation',
                user?.primaryRoom?.location || filteredLocations.value[0]?.id
            );
        }
    }

    return {
        availableLocations: readonlyLocations,
        filteredLocations,
        updateLocations,
        selectDefaultLocation,
    };
});
