import {defineStore} from 'pinia';
import {useRoute} from 'vue-router';
import {useSessionStorage, whenever} from '@vueuse/core';
import {unwrapRouteQueryValue} from '../shared/helpers/routeHelper';

const searchParams = new URLSearchParams(window.location.search);
const queryInMsTeams = searchParams.get('inMSTeams') === 'true';
const queryTeamsType = searchParams.get('teamsType');

export const useMsTeamsStore = defineStore('msTeams', () => {
    const route = useRoute();

    const isMSTeams = useSessionStorage('isMSTeams', queryInMsTeams);

    whenever(
        () => route.query.inMSTeams,
        () => (isMSTeams.value = true)
    );

    const teamsType = useSessionStorage('teamsType', queryTeamsType);

    whenever(
        () => route.query.teamsType,
        value => (teamsType.value = unwrapRouteQueryValue(value))
    );

    return {isMSTeams, teamsType};
});
