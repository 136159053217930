import API from '../api';
import WeekOverviewEntry from '../models/view/WeekOverviewEntry';
import WeekOverviewTeamFiltering from '../models/view/WeekOverviewTeamFiltering';
import TeamFilterView from '../models/view/TeamFilterView';
import WeekOverviewUserDetailsView from '../models/view/WeekOverviewUserDetailsView';
import SelectView from '../models/view/SelectView.js';

export default () => ({
    browse(weekNumber, year) {
        return API.postJSON('/api/week-overview/calendar', {
            weekNumber,
            year,
        })
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new WeekOverviewEntry(singleRaw)));
    },
    userDetails(user, weekNumber, year) {
        const searchParams = new URLSearchParams();

        searchParams.append('user', user);
        searchParams.append('weekNumber', weekNumber);
        searchParams.append('year', year);
        return API.get(`/api/week-overview/user-details?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => new WeekOverviewUserDetailsView(raw));
    },
    getFilteredList(limit, offset, year, weekNumber, teams) {
        const searchParams = new URLSearchParams();

        searchParams.append('limit', limit);
        searchParams.append('offset', offset);
        searchParams.append('year', year);
        searchParams.append('weekNumber', weekNumber);
        teams.forEach(team => searchParams.append('teams[]', team));

        return API.get(`/api/week-overview/information-list/team?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw =>
                raw.map(singleRaw => {
                    const teamFiltering = new WeekOverviewTeamFiltering(singleRaw);

                    teamFiltering.limit ??= limit;
                    teamFiltering.offset ??= offset;

                    return teamFiltering;
                })
            );
    },
    filterTeams(name, offset, limit) {
        const baseUrl = '/api/week-overview/teams/filter';
        const searchParams = new URLSearchParams();

        if (name) {
            searchParams.append('name', name);
        }

        if (offset) {
            searchParams.append('offset', offset);
        }

        if (limit) {
            searchParams.append('limit', limit);
        }

        return API.get(`${baseUrl}?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new TeamFilterView(singleRaw)));
    },
    users(search) {
        const query = search ? `?name=${search}` : '';

        return API.get(`/api/week-overview/users${query}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new SelectView(singleRaw)));
    },
});
