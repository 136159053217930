import API from '../api';

export const getTranslations = languageKey => {
    let url = '/api/translations';

    if (languageKey) {
        const urlParams = new URLSearchParams();

        urlParams.set('locale', languageKey);

        url = `${url}?${urlParams.toString()}`;
    }
    return fetch(url, {method: 'GET'})
        .then(API.handle)
        .catch(API.error)
        .then(json => {
            return JSON.parse(json.data) ?? undefined;
        });
};
