import colors from './colors';

// Options applying to all charts in the app
export const globalOptions = {
    credits: {
        enabled: false,
    },
    chart: {
        style: {
            fontFamily: 'brandon-grotesque',
        },
    },
    //TODO: implement accessibility
    accessibility: {
        enabled: false,
    },
    tooltip: {
        borderWidth: 0,
        useHTML: true,
        className: 'bg-background-lighter shadow-input rounded-md',
        outside: true,
    },
    plotOptions: {
        pie: {
            borderWidth: 5,
            borderRadius: 6,
        },
        series: {
            states: {
                hover: {
                    halo: null,
                    brightness: 0,
                },
            },
            minPointLength: 2,
        },
    },
};

export default {
    mixins: [colors],
    computed: {
        restColor() {
            return this.desklyColors.background;
        },
        chartColorsArray() {
            const colors = Object.values(this.chartColors.resources);
            return [
                ...colors.filter((color, index) => index % 2 === 0),
                ...colors.filter((color, index) => index % 2 === 1),
            ];
        },
    },
};
