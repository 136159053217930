import {translate} from '../../../shared/mixins/translation';
import SeatView from './SeatView';
import ZoneView from './ZoneView';

export default class DayBookingView {
    constructor(json) {
        this.id = json['id'];
        this.firstName = translate(json['firstName']);
        this.lastName = json['lastName'];
        this.timeframe = translate(json['timeframe']);
        this.date = json['date'];
        this.createdAt = json['createdAt'];
        this.userId = json['userId'];
        this.seat = null;
        this.zone = null;
        if (json.seat) {
            this.seat = new SeatView(json['seat']);
        }
        if (json.zone || json.resource) {
            this.zone = new ZoneView(json.zone || json.resource);
        }
        this.teamsChatLink = json['teamsChatLink'] ?? null;
        this.teamsCallLink = json['teamsCallLink'] ?? null;
    }
}
