<template>
    <div
        aria-atomic="true"
        aria-live="assertive"
        class="shadow-md my-4 w-full rounded-md border-0 border-l-6 border-solid backdrop-blur-[10px]"
        :class="severityClasses.wrapper"
        role="alert"
    >
        <div
            class="flex rounded-r-md border-y border-r p-4"
            :class="[message.summary ? 'items-start' : 'items-center', severityClasses.border]"
        >
            <toast-message
                :close-callback="closeCallback"
                :date="message.customData?.date"
                :detail="message.detail"
                :link="message.customData?.link"
                :prevent-close-on-click="preventCloseOnClick"
                :severity="message.severity"
                :summary="message.summary"
            />
            <div>
                <button
                    v-if="closeCallback"
                    aria-label="Close"
                    class="relative ml-auto flex size-8 items-center justify-center overflow-hidden rounded-full bg-transparent transition duration-200 ease-in-out"
                    :class="severityClasses.closeButton"
                    type="button"
                    @click="closeCallback()"
                >
                    <close-icon />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import ToastMessage from './ToastMessage.vue';
import CloseIcon from '../icons/CloseIcon.vue';

const props = defineProps({
    message: {
        type: Object,
        default: () => {},
    },
    closeCallback: {
        type: [Function, null],
        default: null,
    },
    preventCloseOnClick: Boolean,
});

const severityClasses = computed(() => {
    switch (props.message.severity) {
        case 'error':
            return {
                wrapper:
                    'toast-error bg-red-100/70 dark:bg-red-500/20 border-red-500 dark:border-red-400 text-red-700 dark:text-red-300',
                border: 'border-red-500/20',
                closeButton: 'hover:bg-red-200/70 dark:hover:bg-red-400/30',
            };
        case 'success':
            return {
                wrapper:
                    'toast-success bg-green-100/70 dark:bg-green-500/20 border-green-500 dark:border-green-400 text-green-700 dark:text-green-300',
                border: 'border-green-500/20',
                closeButton: 'hover:bg-green-200/70 dark:hover:bg-green-400/30',
            };
        case 'warn':
            return {
                wrapper:
                    'toast-warn bg-yellow-100/70 dark:bg-yellow-500/20 border-yellow-500 dark:border-yellow-400 text-yellow-700 dark:text-yellow-300',
                border: 'border-yellow-500/20',
                closeButton: 'hover:bg-yellow-200/70 dark:hover:bg-yellow-400/30',
            };
        case 'info':
            return {
                wrapper:
                    'toast-info bg-blue-100/70 dark:bg-blue-500/20 border-blue-500 dark:border-blue-400 text-blue-700 dark:text-blue-300',
                border: 'border-blue-500/20',
                closeButton: 'hover:bg-blue-200/70 dark:hover:bg-blue-400/30',
            };
        default:
            return '';
    }
});
</script>
