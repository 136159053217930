const UPDATE_AVAILABLE_TIMEFRAMES = 'UPDATE_AVAILABLE_TIMEFRAMES';

const state = () => ({
    //depending on the selected location in filterLocation.js
    availableTimeframes: [],
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} timeframes list of timeframes in location
     */
    updateAvailableTimeframes({commit}, timeframes) {
        commit(UPDATE_AVAILABLE_TIMEFRAMES, timeframes);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Array} timeframes list of timeframes in location
     */
    [UPDATE_AVAILABLE_TIMEFRAMES](state, timeframes) {
        state.availableTimeframes = timeframes;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
