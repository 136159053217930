import ZoneView from '../view/ZoneView';

export default class ZoneForm extends ZoneView {
    constructor(json) {
        super(json);
        this.capacity = this.maxCapacity;

        delete this.availableCapacity;
        delete this.maxCapacity;
    }

    static createEmpty() {
        return new ZoneForm({
            id: '',
            number: -1,
            name: '',
            defaultName: '',
            dimensions: {},
            availableCapacity: -1,
            maxCapacity: 10,
            blocked: false,
            characteristics: [],
            floorName: '',
            locationName: '',
            locationId: '',
            room: '',
            roomName: '',
            authorizationGroups: [],
            calendarResourceId: '',
            isFavorite: false,
        });
    }
}
