export const OIDC_STATE_PREFIX = 'oidc';

export const SIMPLE = 'simple';
export const CUSTOM = 'custom';
export const AZURE = 'azure';
export const CONFIDENTIAL = 'confidential';
export const ADFS = 'adfs';

export function generateRandomString(length) {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    while (length) {
        const bytes = new Uint8Array(16);
        const random = window.crypto.getRandomValues(bytes);

        random.forEach(character => {
            if (!length) {
                return;
            }
            if (character < charset.length) {
                result += charset[character];
                length--;
            }
        });
    }
    return result;
}
