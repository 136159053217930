import {defineStore} from 'pinia';
import {useLocalStorage, useNow, useSessionStorage, type Serializer} from '@vueuse/core';
import dayjs, {Dayjs} from 'dayjs';
import {computed, readonly, ref, watchEffect} from 'vue';
import {normalizeDate} from '../shared/dateTimeHelper';
import {type DateRange} from '../api/models/dateTime/dateRange';

export const useApiStore = defineStore('apiStore', () => {
    return {
        ...useVersionTimestamp(),
        ...useRateLimiting(),
    };
});

function useVersionTimestamp() {
    const versionTimestamp = useLocalStorage<Dayjs>('versionToastTimestamp', null);

    function setTimestamp() {
        versionTimestamp.value = dayjs();
    }

    function isToastMuted() {
        if (!versionTimestamp.value) {
            return false;
        }

        return dayjs().diff(versionTimestamp.value, 'minutes') < 10;
    }

    return {setTimestamp, isToastMuted};
}

function useRateLimiting() {
    const dateRangeSerializer: Serializer<DateRange | null> = {
        read: value => {
            if (!value) {
                return null;
            }
            const parsed = JSON.parse(value);
            if (!parsed) {
                return null;
            }
            return {
                from: dayjs(parsed.from),
                to: dayjs(parsed.to),
            };
        },
        write: value => {
            const mapped = value ? {from: value.from.toDate(), to: value.to.toDate()} : value;
            return JSON.stringify(mapped);
        },
    };

    const waitPeriodInSeconds = 60;

    const rateLimitingTimeRange = useSessionStorage<DateRange | null>(
        'rateLimitingTimeRange',
        null,
        {serializer: dateRangeSerializer}
    );

    const isRateLimited = ref(
        !!rateLimitingTimeRange.value && rateLimitingTimeRange.value.to.isAfter(dayjs())
    );

    if (!isRateLimited.value) {
        rateLimitingTimeRange.value = null;
    }

    const {now, pause, resume} = useNow({controls: true, interval: 1000});

    function clamp(number: number, min: number, max: number) {
        return Math.max(min, Math.min(number, max));
    }

    const progress = computed(() => {
        if (!rateLimitingTimeRange.value) {
            return isRateLimited.value ? 1 : 0;
        }
        const normalizedNow = normalizeDate(now.value);
        const secondsSinceStart = rateLimitingTimeRange.value.to.diff(normalizedNow, 'seconds');
        return 1 - clamp(secondsSinceStart, 0, waitPeriodInSeconds) / waitPeriodInSeconds;
    });

    watchEffect(() => {
        if (progress.value >= 1 || !rateLimitingTimeRange.value) {
            rateLimitingTimeRange.value = null;
            pause();
        } else {
            resume();
        }
    });

    function startRateLimiting() {
        isRateLimited.value = true;
        rateLimitingTimeRange.value = {
            from: dayjs(),
            to: dayjs().add(waitPeriodInSeconds, 'seconds'),
        };
    }

    return {isRateLimited: readonly(isRateLimited), progress, startRateLimiting};
}
