import MeetingRoomView from './MeetingRoomView';

export default class MeetingRoomFilterView {
    constructor(json) {
        this.meetingRooms = [];
        if (json.meetingRooms) {
            this.meetingRooms = json.meetingRooms.map(
                meetingRoom => new MeetingRoomView(meetingRoom)
            );
        }
        this.count = json.count;
        this.roomAvailableMeetingRooms = [];
        if (json.roomAvailableMeetingRooms) {
            this.roomAvailableMeetingRooms = json.roomAvailableMeetingRooms;
        }
    }
}
