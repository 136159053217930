import {useUserStore} from '../../stores/userStore';
import {saveRoute} from '../guardHelper';

/**
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export const requireAuth = async to => {
    const {isAuthenticated} = useUserStore();

    if (!isAuthenticated) {
        saveRoute(to);
        return {name: 'Login'};
    }
};
