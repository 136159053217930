import {cleanupCookieRoute, getRouteFromCookie} from '../../api/helpers/cookieHelper';
import {debug} from '../../shared/helpers/debug';

/**
 *
 * @param {RouteLocationNormalized} to
 * @param {RouteLocationNormalized} from
 * @return {NavigationGuardResult}
 */
export const checkForTargetRouteAfterLogin = (to, from) => {
    const cookieRoute = getRouteFromCookie();

    if (!cookieRoute) {
        debug.log('no cookie route and no redirection', cookieRoute);

        return;
    }

    const {name, meta, params, query} = cookieRoute;
    debug.log('detectedRoute', {name, meta, params, query});

    if (['Login', 'Logout', 'Home', 'CallBack', 'GoogleCallback'].includes(name)) {
        debug.log('target is home or logout or callback');
        cleanupCookieRoute();
        return;
    }

    if (meta?.public) {
        debug.log('target is public');
        cleanupCookieRoute();
        return;
    }

    if (query) {
        delete query.token;
        delete query.refreshToken;
        delete query.authType;
    }

    debug.log('target:', {name, meta, params, query});

    debug.log('cleanup cookie route');
    cleanupCookieRoute();

    if (from.name === name) {
        debug.log('from equals target - let intentional origin pass');

        return;
    }
    return {name, meta, params, query};
};
