import ResourceView from './ResourceView';

export default class WeekOverviewEntry {
    constructor(json) {
        this.date = json.date;
        if (json.seatDayBookings.length) {
            this.seatDayBookings = json.seatDayBookings.map(
                dayBooking => new ResourceView(dayBooking)
            );
        }

        if (json.zoneDayBookings.length) {
            this.zoneDayBookings = json.zoneDayBookings.map(
                dayBooking => new ResourceView(dayBooking)
            );
        }

        if (json.meetingRoomDayBookings.length) {
            this.meetingRoomDayBookings = json.meetingRoomDayBookings.map(
                dayBooking => new ResourceView(dayBooking)
            );
        }

        this.userStatus = json.userStatus;
    }
}
