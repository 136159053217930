import moment from 'moment-timezone';
import WeekOverviewTeamFiltering from '../../api/models/view/WeekOverviewTeamFiltering';
import WeekOverviewRepository from '../../api/repositories/WeekOverviewRepository';
import FilterUserRepository from '../../api/repositories/FilterUserRepository';

const REPLACE_TEAMS = 'REPLACE_TEAMS',
    CHANGE_SELECTED_YEAR = 'CHANGE_SELECTED_YEAR',
    MOVE_WEEK = 'MOVE_WEEK',
    CHANGE_SELECTED_WEEK = 'CHANGE_SELECTED_WEEK',
    ADD_TEAM_FILTERING = 'ADD_TEAM_FILTERING',
    REMOVE_TEAM_FILTERING = 'REMOVE_TEAM_FILTERING',
    SET_USERS_LOADING = 'SET_USERS_LOADING',
    SELECT_USER = 'SELECT_USER',
    SELECT_USERS = 'SELECT_USERS',
    CLEAR_USERS = 'CLEAR_USER',
    DESELECT_USER = 'DESELECT_USER',
    FILTER_USERS = 'FILTER_USERS',
    FILTER_TEAMS = 'FILTER_TEAMS',
    DESELECT_TEAM = 'DESELECT_TEAM',
    ADD_USERS_TO_FILTERED_TEAM = 'ADD_USERS_TO_FILTERED_TEAM',
    SET_LOAD_MORE_STATE = 'SET_LOAD_MORE_STATE',
    SET_ACTIVE_DAY_BOOKINGS_TEAM_USER = 'SET_ACTIVE_DAY_BOOKINGS_TEAM_USER',
    SET_ACTIVE_DAY_BOOKINGS_USER = 'SET_ACTIVE_DAY_BOOKINGS_USER',
    SET_ALLOWED_TO_DELETE_USER = 'SET_ALLOWED_TO_DELETE_USER',
    SET_ALLOWED_TO_DELETE_TEAM_USER = 'SET_ALLOWED_TO_DELETE_TEAM_USER',
    SET_ACTIVE_USER = 'SET_ACTIVE_USER',
    SET_ACTIVE_TEAM_USER = 'SET_ACTIVE_TEAM_USER',
    TOGGLE_TEAM = 'TOGGLE_TEAM',
    SET_TEMPLATE = 'SET_TEMPLATE',
    SET_AVAILABLE_TEMPLATES = 'SET_AVAILABLE_TEMPLATES',
    SET_USER_FILTER_OPTIONS = 'SET_USER_FILTER_OPTIONS',
    SET_ALL_TEAMS_INITIAL_LOADING = 'SET_ALL_TEAMS_INITIAL_LOADING',
    RESET_STATE = 'RESET_STATE';

const getDefaultState = () => {
    return {
        selectedTeams: [],
        selectedUsers: [],
        selectedTemplate: null,
        isInitialTemplateSet: false,
        activeDayBookingsTeamUser: [],
        activeDayBookingsUser: [],
        allowedToDeleteTeamUser: false,
        allowedToDeleteUser: false,
        activeUser: null,
        activeTeamUser: null,
        allowedToDelete: false,
        filteredUsers: [],
        selectedWeek: moment().isoWeek(),
        selectedYear: moment().year(),
        usersLoading: false,
        teamFilter: [],
        availableTemplates: null,
        userFilterOptions: [],
    };
};
const state = getDefaultState();

const actions = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     * @param {array} teamList list of team ids
     * @param {function} dispatch call other action
     */
    replaceSelectedTeams({state, commit, dispatch}, teamList) {
        commit(REPLACE_TEAMS, teamList);

        const filteredTeams = state.teamFilter.map(({id}) => id);

        filteredTeams.forEach(team => {
            if (teamList.some(({id}) => team === id)) {
                return;
            }

            commit(REMOVE_TEAM_FILTERING, team);
        });

        teamList.forEach(teamToLookFor => {
            //check if team exists in list
            if (filteredTeams.some(team => team === teamToLookFor.id)) {
                //do nothing
                return;
            }

            //team does not exist and needs to be filtered
            commit(ADD_TEAM_FILTERING, teamToLookFor.id);
            dispatch('filterTeam', teamToLookFor.id);
        });
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} numberToAdd number to add to selected week
     */
    moveSelectedWeek({commit}, numberToAdd) {
        commit(MOVE_WEEK, numberToAdd);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     * @param {array} selectedUser a list of users, who have been selected in the selected filter
     */
    selectUser({state, commit}, selectedUser) {
        commit(SELECT_USER, selectedUser);
        commit(SET_USERS_LOADING, true);
        FilterUserRepository()
            .getFilteredList(state.selectedYear, state.selectedWeek, state.selectedUsers)
            .then(response => {
                commit(FILTER_USERS, response);
            })
            .then(() => {
                commit(SET_USERS_LOADING, false);
            });
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     * @param {array} selectedUsers a list of users, who have been selected in the selected filter
     */
    setSelectUsers({state, commit}, selectedUsers) {
        commit(SELECT_USERS, selectedUsers);
        commit(SET_USERS_LOADING, true);
        FilterUserRepository()
            .getFilteredList(state.selectedYear, state.selectedWeek, state.selectedUsers)
            .then(response => {
                commit(FILTER_USERS, response);
            })
            .then(() => {
                commit(SET_USERS_LOADING, false);
            });
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     */
    updateSelectedUsers({state, commit}) {
        if (!state.selectedUsers.length) {
            return;
        }
        commit(SET_USERS_LOADING, true);
        FilterUserRepository()
            .getFilteredList(state.selectedYear, state.selectedWeek, state.selectedUsers)
            .then(response => {
                commit(FILTER_USERS, response);
            })
            .then(() => {
                commit(SET_USERS_LOADING, false);
            });
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} dayBookings a list of dayBookings that belongs to the user that is currently active in a team
     */
    setActiveDayBookingsTeamUser({commit}, dayBookings) {
        commit(SET_ACTIVE_DAY_BOOKINGS_TEAM_USER, dayBookings);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} dayBookings a list of dayBookings that belongs to the user that is currently active
     */
    setActiveDayBookingsUser({commit}, dayBookings) {
        commit(SET_ACTIVE_DAY_BOOKINGS_USER, dayBookings);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} isAllowedToDelete a boolean value, that tells if the bookings from the active user are allowed to be deleted
     */
    setAllowedToDeleteTeamUser({commit}, isAllowedToDelete) {
        commit(SET_ALLOWED_TO_DELETE_TEAM_USER, isAllowedToDelete);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} isAllowedToDelete a boolean value, that tells if the bookings from the active user are allowed to be deleted
     */
    setAllowedToDeleteUser({commit}, isAllowedToDelete) {
        commit(SET_ALLOWED_TO_DELETE_USER, isAllowedToDelete);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {String} userId the id of the user, that has been selected
     */
    setActiveTeamUser({commit}, userId) {
        commit(SET_ACTIVE_TEAM_USER, userId);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {String} userId the id of the user, that has been selected
     */
    setActiveUser({commit}, userId) {
        commit(SET_ACTIVE_USER, userId);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} filteredUsers new filteredUsers
     */
    setFilteredUsers({commit}, filteredUsers) {
        commit(FILTER_USERS, filteredUsers);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {string} userId id of the user who should be deselected
     */
    deselectUser({commit}, userId) {
        commit(DESELECT_USER, userId);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {string} teamId id of the team who should be deselected
     */
    deselectTeam({commit}, teamId) {
        commit(DESELECT_TEAM, teamId);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} filteredTeams new filteredTeams
     */
    setFilteredTeams({commit}, filteredTeams) {
        commit(FILTER_TEAMS, filteredTeams);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     */
    updateSelectedTeams({state, commit}) {
        if (!state.selectedTeams.length) {
            return;
        }

        commit(SET_ALL_TEAMS_INITIAL_LOADING);

        WeekOverviewRepository()
            .getFilteredList(
                WeekOverviewTeamFiltering.defaultPageSize,
                0,
                state.selectedYear,
                state.selectedWeek,
                state.selectedTeams.map(selectedTeam => selectedTeam.id)
            )
            .then(selectedTeams => commit(FILTER_TEAMS, selectedTeams));
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} selectedYear selected year
     */
    changeSelectedYear({commit}, selectedYear) {
        commit(CHANGE_SELECTED_YEAR, selectedYear);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {array} selectedWeek selected week
     */
    changeSelectedWeek({commit}, selectedWeek) {
        commit(CHANGE_SELECTED_WEEK, selectedWeek);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Boolean} loading loading state of users
     */
    changeUsersLoading({commit}, loading) {
        commit(SET_USERS_LOADING, loading);
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     * @param {string} teamToFilter id of team to filter
     */
    filterTeam({state, commit}, teamToFilter) {
        //get team from list
        const foundTeam = state.teamFilter.find(team => team.id === teamToFilter);

        if (!foundTeam) {
            return;
        }

        //filter team and add response to team filtering
        WeekOverviewRepository()
            .getFilteredList(
                foundTeam.limit,
                foundTeam.offset,
                state.selectedYear,
                state.selectedWeek,
                [foundTeam.id]
            )
            .then(response => {
                const team = response[0];
                team.limit = foundTeam.limit;
                team.offset = foundTeam.offset;
                team.visible = foundTeam.visible;

                commit(FILTER_TEAMS, [team]);
            });
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     * @param {string} teamId id of the team
     */
    loadMoreTeamUser({state, commit}, teamId) {
        //increase limit and set offset
        commit(SET_LOAD_MORE_STATE, teamId);

        //get team from list
        const foundTeam = state.teamFilter.find(team => team.id === teamId);

        if (!foundTeam) {
            return;
        }

        //prevent vuex store state mutation by copying state object
        const copiedTeam = Object.assign({}, foundTeam);
        const updatedLimit = (copiedTeam.limit += WeekOverviewTeamFiltering.defaultPageSize);
        const updatedOffset = (copiedTeam.offset += WeekOverviewTeamFiltering.defaultPageSize);

        //load more users from team
        WeekOverviewRepository()
            .getFilteredList(
                WeekOverviewTeamFiltering.defaultPageSize,
                updatedOffset,
                state.selectedYear,
                state.selectedWeek,
                [copiedTeam.id]
            )
            .then(filteredTeam => {
                const payload = {
                    teamToUpdate: filteredTeam[0],
                    limit: updatedLimit,
                    offset: updatedOffset,
                };
                commit(ADD_USERS_TO_FILTERED_TEAM, payload);
            });
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {string} team id of the team
     */
    toggleTeamVisibility({commit}, team) {
        commit(TOGGLE_TEAM, team);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} template currently selected template
     */
    setTemplate({commit}, template) {
        commit(CLEAR_USERS);
        commit(SET_USER_FILTER_OPTIONS, []);
        commit(SET_TEMPLATE, template);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} templates list of available templates
     */
    setAvailableTemplates({commit}, templates) {
        commit(SET_AVAILABLE_TEMPLATES, templates);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {Array} options list of user filter options
     */
    setUserFilterOptions({commit}, options) {
        commit(SET_USER_FILTER_OPTIONS, options);
    },
    resetState({commit}) {
        commit(RESET_STATE);
    },
    /**
     * Resets everything but keeps the selected year / week and available templates
     * @param {Object} state modules source of truth
     * @param {function} commit commits mutation
     */
    resetFilterAndSelection({state, commit}) {
        const {availableTemplates, selectedWeek, selectedYear, isInitialTemplateSet} = state;

        commit(RESET_STATE);

        commit(SET_AVAILABLE_TEMPLATES, availableTemplates);
        commit(CHANGE_SELECTED_WEEK, selectedWeek);
        commit(CHANGE_SELECTED_YEAR, selectedYear);

        if (isInitialTemplateSet) {
            // Implicitly set flag that a template was already selected
            // in order to prevent setting the template again on week change
            commit(SET_TEMPLATE, null);
        }
    },
};
const mutations = {
    [REPLACE_TEAMS](state, teamList) {
        state.selectedTeams = teamList;
    },
    [MOVE_WEEK](state, numberToAdd) {
        state.selectedWeek += numberToAdd;
        if (!state.selectedWeek) {
            state.selectedWeek = moment(--state.selectedYear).isoWeeksInISOWeekYear() - 1;
        }
        if (state.selectedWeek >= moment(state.selectedYear).isoWeeksInISOWeekYear()) {
            state.selectedWeek = 1;
            state.selectedYear++;
        }
    },
    [CHANGE_SELECTED_YEAR](state, selectedYear) {
        state.selectedYear = selectedYear;
    },
    [CHANGE_SELECTED_WEEK](state, selectedWeek) {
        state.selectedWeek = selectedWeek;
    },
    [SELECT_USER](state, selectedUser) {
        if (!state.selectedUsers.includes(selectedUser)) {
            state.selectedUsers.push(selectedUser);
        }
    },
    [DESELECT_USER](state, deselectedUserId) {
        state.filteredUsers = state.filteredUsers.filter(user => {
            return user.id !== deselectedUserId;
        });

        state.selectedUsers = state.selectedUsers.filter(user => {
            return user !== deselectedUserId;
        });
    },
    [DESELECT_TEAM](state, deselectedTeamId) {
        state.teamFilter = state.teamFilter.filter(team => {
            return team.id !== deselectedTeamId;
        });
        state.selectedTeams = state.selectedTeams.filter(team => {
            return team.id !== deselectedTeamId;
        });
    },
    [FILTER_USERS](state, filteredUsers) {
        state.filteredUsers = filteredUsers;
        state.selectedUsers = filteredUsers.map(user => user.id);

        // remove active user if they have no bookings
        if (!state.activeUser) {
            return;
        }

        const hasActiveUserDetails = state.filteredUsers
            .find(({id}) => id === state.activeUser)
            ?.informationEntries?.some(options => options.bookingCount);

        if (!hasActiveUserDetails) {
            state.activeUser = null;
        }
    },
    [SET_ACTIVE_DAY_BOOKINGS_TEAM_USER](state, dayBookings) {
        state.activeDayBookingsTeamUser = dayBookings;
    },
    [SET_ACTIVE_DAY_BOOKINGS_USER](state, dayBookings) {
        state.activeDayBookingsUser = dayBookings;
    },
    [SET_ALLOWED_TO_DELETE_TEAM_USER](state, allowedToDelete) {
        state.allowedToDeleteTeamUser = allowedToDelete;
    },
    [SET_ALLOWED_TO_DELETE_USER](state, allowedToDelete) {
        state.allowedToDeleteUser = allowedToDelete;
    },
    [SET_ACTIVE_TEAM_USER](state, userId) {
        state.activeTeamUser = userId;
    },
    [SET_ACTIVE_USER](state, userId) {
        state.activeUser = userId;
    },
    [SET_USERS_LOADING](state, loadingState) {
        state.usersLoading = loadingState;
    },
    [ADD_TEAM_FILTERING](state, teamId) {
        const teamFiltering = WeekOverviewTeamFiltering.createEmpty();
        teamFiltering.id = teamId;
        state.teamFilter.push(teamFiltering);
    },
    [REMOVE_TEAM_FILTERING](state, teamId) {
        const index = state.teamFilter.findIndex(({id}) => id === teamId);

        if (index < 0) {
            return;
        }

        state.teamFilter.splice(index, 1);
    },
    [FILTER_TEAMS](state, teamsToUpdate) {
        state.teamFilter = state.teamFilter.map(team => {
            const teamToUpdate = teamsToUpdate.find(teamToUpdate => teamToUpdate.id === team.id);
            if (!teamToUpdate) {
                return team;
            }
            const previousVisibility = team.visible;
            team = teamToUpdate;
            team.visible = previousVisibility;
            team.initialLoading = false;
            return team;
        });

        // remove active team user if they have no bookings
        if (!state.activeTeamUser) {
            return;
        }

        const activeTeamUserInformationEntries = state.teamFilter
            .flatMap(({users}) => users)
            .find(({id}) => id === state.activeTeamUser)?.informationEntries;

        const hasActiveTeamUserDetails = activeTeamUserInformationEntries?.some(
            options => options.bookingCount
        );

        if (!hasActiveTeamUserDetails) {
            state.activeTeamUser = null;
        }
    },
    [ADD_USERS_TO_FILTERED_TEAM](state, payload) {
        //get team index from list to update its data
        const teamIndex = state.teamFilter.findIndex(team => team.id === payload.teamToUpdate.id);

        if (!teamIndex < 0) {
            return;
        }

        state.teamFilter[teamIndex].limit = payload.limit;
        state.teamFilter[teamIndex].offset = payload.offset;
        state.teamFilter[teamIndex].users.push(...payload.teamToUpdate.users);
        state.teamFilter[teamIndex].loadMoreLoading = false;
    },
    [SET_LOAD_MORE_STATE](state, teamId) {
        //get team index from list to set loading state
        const teamIndex = state.teamFilter.findIndex(team => team.id === teamId);

        if (!teamIndex < 0) {
            return;
        }

        state.teamFilter[teamIndex].loadMoreLoading = true;
    },
    [TOGGLE_TEAM](state, teamId) {
        const teamIndex = state.teamFilter.findIndex(team => team.id === teamId);

        if (teamIndex === -1) {
            return;
        }

        state.teamFilter[teamIndex].visible = !state.teamFilter[teamIndex].visible;
    },
    [CLEAR_USERS](state) {
        state.selectedUsers.length = 0;
    },
    [SELECT_USERS](state, selectedUsers) {
        state.selectedUsers.push(
            ...selectedUsers.filter(
                selectedUser => !state.selectedUsers.some(({id}) => id === selectedUser.id)
            )
        );
    },
    [SET_TEMPLATE](state, template) {
        state.selectedTemplate = template;
        state.isInitialTemplateSet = true;
    },
    [SET_AVAILABLE_TEMPLATES](state, templates) {
        state.availableTemplates = templates;
    },
    [SET_USER_FILTER_OPTIONS](state, options) {
        state.userFilterOptions = options;
    },
    [SET_ALL_TEAMS_INITIAL_LOADING](state) {
        state.teamFilter.forEach(team => (team.initialLoading = true));
    },
    [RESET_STATE](state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
