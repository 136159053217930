export default class DayBookingOption {
    from = null;
    until = null;

    constructor(json) {
        this.date = json.date;
        this.timeslots = json.timeslots;
        this.bookedCapacity = json.bookedCapacity;
    }
}
