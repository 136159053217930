export default {
    root: {
        class: [
            'relative',
            'inline-flex',
            'align-bottom',
            'w-5',
            'h-5',
            'cursor-pointer',
            'select-none',
        ],
    },
    box: ({props, context}) => ({
        class: [
            // Alignment
            'flex',
            'items-center',
            'justify-center',
            // Size
            'w-5',
            'h-5',
            // Shape
            'rounded',
            'border',
            // Colors
            {
                'border-surface-300': !context.checked && !props.invalid,
                'bg-surface-0': !context.checked && !props.invalid && !props.disabled,
                'border-primary bg-primary': context.checked,
            },
            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            {'border-red-500': props.invalid},
            // States
            {
                'peer-hover:border-surface-400':
                    !props.disabled && !context.checked && !props.invalid,
                'peer-hover:bg-primary-hover peer-hover:border-primary-hover':
                    !props.disabled && context.checked,
                'peer-focus-visible:z-10 peer-focus-visible:outline-none peer-focus-visible:outline-offset-0 peer-focus-visible:ring-1 peer-focus-visible:ring-primary-500':
                    !props.disabled,
                'bg-surface-200 select-none pointer-events-none cursor-default': props.disabled,
            },
            // Transitions
            'transition-colors',
            'duration-200',
        ],
    }),
    input: {
        class: [
            'peer',
            'w-full ',
            'h-full',
            'absolute',
            'top-0 left-0',
            'z-10',
            'p-0',
            'm-0',
            'opacity-0',
            'rounded',
            'outline-none',
            'border border-surface-300',
            'appearance-none',
            'cursor-pointer',
        ],
    },
    icon: {
        class: ['w-[0.875rem]', 'h-[0.875rem]', 'text-white', 'transition-all', 'duration-200'],
    },
};
