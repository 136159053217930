import {useLocalStorage, type Serializer} from '@vueuse/core';
import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import {useMatomoEventTracker} from '../shared/matomoTracking';

const nullableBoolSerializer: Serializer<boolean | null> = {
    write(value) {
        if (value === null) {
            return '';
        }
        return value ? 'true' : 'false';
    },
    read(value) {
        if (typeof value === 'string') {
            if (value.length === 0) {
                return null;
            }
            return value === 'true';
        }
        return null;
    },
};

export const useNewNavigationStore = defineStore('newNavigation', () => {
    const newNavigation = useLocalStorage<boolean | null>('newNavigation', null, {
        serializer: nullableBoolSerializer,
    });

    const showNewNavigationOverlay = ref(false);

    const matomoTracker = useMatomoEventTracker('NewNavigation');

    watch(newNavigation, (current, previous) => {
        if (!previous && current) {
            matomoTracker.trackEvent('Change', 'Active');
        } else if (!current && previous) {
            matomoTracker.trackEvent('Change', 'Inactive');
        }
    });

    return {newNavigation, showNewNavigationOverlay};
});
