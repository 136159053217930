import baseTranslations from './base.json';
import {translateWithTranslations} from '../../mixins/translation';
import {deepMap} from '../../helpers/deepMap';
import dayjs from 'dayjs';

const importMap = import.meta.glob('./**.json');

export async function getPrimeVueLocale(locale, translations) {
    const translated = deepMap(baseTranslations, val => {
        const translationString = translateWithTranslations(translations, val);
        return adjustPlaceholderSyntax(translationString);
    });

    const response = await importMap[`./${locale}.json`]();

    return {...getLocalizedCalendarTranslations(), ...translated, ...response};
}

const parameterRegEx = /%(.+?)%/g;

/**
 * Replaces parameter occurrences in the format '%parameter%'
 * with the required PrimeVue format '{parameter}'
 * @param {String} translationString
 */
function adjustPlaceholderSyntax(translationString) {
    if (typeof translationString !== 'string') {
        return translationString;
    }

    return translationString.replaceAll(parameterRegEx, (_, parameter) => {
        return `{${parameter}}`;
    });
}

function getLocalizedCalendarTranslations() {
    return {
        monthNames: dayjs.months(),
        monthNamesShort: dayjs.monthsShort(),
    };
}
