import jwt_decode from 'jwt-decode';
import {useToast} from '../../shared/components/toast/useToast.js';
import {translate} from '../../shared/mixins/translation';
import {ADFS, AZURE, CONFIDENTIAL, CUSTOM, SIMPLE} from '../../api/authentication/OIDCHelper';
import AuthClient from '../../api/client/authClient';
import OIDCClient from '../../api/authentication/OIDCClient';
import store from '../../store';
import {useOidcStore} from '../../stores/oidcStore';
import {useAuthenticationSettings} from '../../stores/authenticationSettingsStore';
import {useAccountStore} from '../../stores/accountStore';
import {useInformationStore} from '../../stores/informationStore';
import {useAuthorizationStore} from '../../stores/authorizationStore';
import * as Sentry from '@sentry/vue';
import {useSecurityStore} from '../../stores/securityStore';

/**
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export const authenticationCallbackRoute = async to => {
    await useAuthenticationSettings().ensureAuthenticationSettingsLoaded();
    const {authenticationSettings} = useAuthenticationSettings();

    if (!authenticationSettings?.type) {
        return {name: 'Login'};
    }

    function handleAuthFailure() {
        OIDCClient.clearStateAndNonce();
        useSecurityStore().clearToken(true);
        return {name: 'Login'};
    }

    try {
        const oidcSuccess = await handleAuthentication(authenticationSettings, to);

        if (!oidcSuccess) {
            return handleAuthFailure();
        }

        const isAuthenticated = await useInformationStore().refresh();

        if (!isAuthenticated) {
            return handleAuthFailure();
        }

        const {account} = useAccountStore();

        await store.dispatch('resources/initializeResourceTypes', {
            accountInformation: account,
            currentRouteName: '',
        });
    } catch (error) {
        const eventId = Sentry.captureException(error);

        useToast().error(translate('app.toast.login.oidc.other'), {
            detail: `${translate(
                'app.toast.login.oidc.other_detail'
            )} <br/> <br/> <b>${eventId}<b/>`,
        });

        return handleAuthFailure();
    }

    return {name: 'Home'};
};

async function handleAuthentication(authenticationSettings, to) {
    Sentry.addBreadcrumb({message: `Authentication type: ${authenticationSettings.type}`});

    const authorizationStore = useAuthorizationStore();
    switch (authenticationSettings.type) {
        case SIMPLE: {
            return handleSimple(to);
        }
        case AZURE:
        case CUSTOM: {
            const oidcStore = useOidcStore();
            oidcStore.idToken = await OIDCClient.handleRequest(window.location.href);
            return true;
        }
        case ADFS:
        case CONFIDENTIAL: {
            const success = await AuthClient.processOidc(window.location.href);
            authorizationStore.resetUnauthorizedRequestsCount();
            return success;
        }
    }
}

function handleSimple(to) {
    const params = [];
    const result = to.hash.substr(1).split('&');

    result.forEach(entry => {
        const [key, value] = entry.split('=');
        params[key] = value;
    });

    const state = OIDCClient.oidcState();
    const nonce = OIDCClient.oidcNonce();

    const decoded = jwt_decode(params['id_token']);

    const invalidNonce = !decoded.nonce || nonce !== decoded.nonce;
    const invalidState = !params['state'] || state !== params['state'];

    if (invalidNonce) {
        useToast().error(translate('app.toast.login.oidc.nonce_wrong'));
        OIDCClient.clearStateAndNonce();
        return false;
    }

    if (invalidState) {
        useToast().error(translate('app.toast.login.oidc.state_wrong'));
        OIDCClient.clearStateAndNonce();
        return false;
    }
    const oidcStore = useOidcStore();
    oidcStore.idToken = params['id_token'];
    OIDCClient.clearStateAndNonce();

    return true;
}
