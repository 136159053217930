import {getRouteFromCookie} from '../../api/helpers/cookieHelper';
import store from '../../store/index';
import {useSecurityStore} from '../../stores/securityStore';
import {unwrapRouteQueryValue} from '../../shared/helpers/routeHelper';
import {useAccountStore} from '../../stores/accountStore';
import {useInformationStore} from '../../stores/informationStore';

/**
 *
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export const authorizeWithQueryToken = async to => {
    if (!('token' in to.query && 'refreshToken' in to.query)) {
        return;
    }

    const securityStore = useSecurityStore();
    const accountStore = useAccountStore();
    const {refresh} = useInformationStore();

    securityStore.token = unwrapRouteQueryValue(to.query.token);
    securityStore.refreshToken = unwrapRouteQueryValue(to.query.refreshToken);

    securityStore.authType =
        'authType' in to.query ? unwrapRouteQueryValue(to.query.authType) : null;

    delete to.query.token;
    delete to.query.refreshToken;
    delete to.query.authType;

    try {
        await refresh();

        await store.dispatch('resources/initializeResourceTypes', {
            accountInformation: accountStore.account,
            currentRouteName: to.name || '',
        });
        if (
            [
                'SeatDayBooking',
                'ZoneDayBooking',
                'MeetingRoomDayBooking',
                'DayBookingOverview',
                'FavoriteSeats',
                'PresentList',
            ].includes(to.name)
        ) {
            return to;
        }

        return getRouteFromCookie() || {name: 'Home', query: to.query};
    } catch {
        securityStore.clearToken(true);

        return {name: 'Login'};
    }
};
