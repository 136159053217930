import {debug} from '../shared/helpers/debug';
import {saveRouteCookie} from '../api/helpers/cookieHelper';

/** @typedef {import('vue-router').NavigationGuardReturn} NavigationGuardReturn */
/** @typedef {NavigationGuardReturn | Promise<NavigationGuardReturn>} NavigationGuardResult */
/** @typedef {import('vue-router').RouteLocationNormalized | {href: string} } RouteLocationNormalized */

export function isPassRouteResult(result) {
    return result === true || result === null || typeof result === 'undefined';
}

/**
 * Wraps the execution of a navigation guard, so that the usage of the guard can be logged.
 * @param {import('vue-router').NavigationGuardWithThis<any>} guard Expects a navigation guard function as input. Next as parameter should not be used.
 * @returns {import('vue-router').NavigationGuardWithThis<any>}
 */
export function wrapGuard(guard) {
    if (guard.length > 2) {
        throw new Error(
            'Navigation guards should only use parameters "to" and "from"; "next" shouldn\'t be used.'
        );
    }

    if (import.meta.env.DEV && !guard.name) {
        throw new Error('Navigation guards should not be anonymous functions');
    }

    return async (to, from) => {
        debug.start(`Running guard: ${guard.name}`);
        try {
            const result = await Promise.resolve(guard(to, from));
            debug.log('Guard result', isPassRouteResult(result) ? '✓ Pass' : result);
            return result;
        } catch (error) {
            debug.error('An error occurred', error);
            throw error;
        } finally {
            debug.end(`Running guard: ${guard.name}`);
        }
    };
}

/**
 *
 * @param {Array<import('vue-router').RouteRecordRaw>} routes
 * @return {Array<import('vue-router').RouteRecordRaw>}
 */
export function wrapRouteGuards(routes) {
    return routes.map(routeRecord => {
        if (routeRecord.children) {
            routeRecord.children = wrapRouteGuards(routeRecord.children);
        }

        if (!routeRecord.beforeEnter) {
            return routeRecord;
        }

        if (routeRecord.beforeEnter instanceof Array) {
            routeRecord.beforeEnter = routeRecord.beforeEnter.map(guard => wrapGuard(guard));
            return routeRecord;
        }

        if (typeof routeRecord.beforeEnter === 'function') {
            const previousBeforeEnter = routeRecord.beforeEnter;
            routeRecord.beforeEnter = wrapGuard(previousBeforeEnter);
            return routeRecord;
        }

        return routeRecord;
    });
}

export function saveRoute(to) {
    const route = JSON.stringify({
        name: to.name,
        params: to.params,
        meta: to.meta,
        query: to.query,
    });

    saveRouteCookie(route);
}
