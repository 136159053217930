<template>
    <vue-final-modal
        v-model="isRateLimited"
        v-cy="'rate-limiting-modal'"
        classes="flex justify-center items-center"
        :click-to-close="false"
        content-class="justify-center w-full md:w-[700px] relative flex flex-col md:h-auto md:max-h-600 overflow-y-auto px-7 py-5 m-5 rounded-md bg-background-lighter shadow-menu"
        :esc-to-close="false"
        overlay-class="!bg-zinc-50/50 backdrop-blur-[5px]"
        :transition="{
            'enter-active-class': 'transition duration-200 ease-in-out transform',
            'enter-class': 'translate-y-full',
            'enter-to-class': 'translate-y-0',
            'leave-active-class': 'transition duration-200 ease-in-out transform',
            'leave-to-class': 'translate-y-full',
            'leave-class': 'translate-y-0',
        }"
    >
        <div class="flex w-full flex-col gap-6">
            <h4 class="text-lg font-bold" v-text="translate('app.rate_limiting.title')" />
            <div v-text="translate('app.rate_limiting.body')" />
            <div
                class="flex w-full max-w-full flex-col flex-nowrap items-center justify-center gap-3 md:flex-row"
            >
                <div class="w-full flex-1 shrink-0 basis-auto md:w-auto">
                    <progress-bar :value="progress * 100" />
                </div>
                <div class="shrink-1 grow-0 basis-auto">
                    <button-primary
                        :disabled="progress < 1"
                        size="small"
                        :text="translate('app.rate_limiting.reload')"
                        @click="reload"
                    >
                        <template #iconLeft>
                            <refresh-icon class="space-5 fill-current text-white" />
                        </template>
                    </button-primary>
                </div>
                <div class="shrink-1 grow-0 basis-auto">
                    <a href="https://desk.ly/help" target="_blank">
                        <button-primary
                            is-external
                            size="small"
                            :text="translate('app.rate_limiting.support')"
                        />
                    </a>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script setup lang="ts">
import {storeToRefs} from 'pinia';
import {useApiStore} from '../../stores/apiStore';
import ButtonPrimary from './core/ButtonPrimary.vue';
import RefreshIcon from './icons/RefreshIcon.vue';
import ProgressBar from './ProgressBar.vue';
import {translate} from '../mixins/translation';

const {isRateLimited, progress} = storeToRefs(useApiStore());

function reload() {
    window.location.reload();
}
</script>
