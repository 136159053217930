import transform from 'lodash/transform';
import isObject from 'lodash/isObject';

/**
 * Maps the values of an object using the specified callbackFn
 * @param {Object} obj
 * @param {function(any, any, Object):any} callbackFn
 * @returns {Object}
 */
export function deepMap(obj, callbackFn) {
    return transform(obj, function (result, val, key) {
        result[key] = isObject(val) ? deepMap(val, callbackFn) : callbackFn(val, key, obj);
    });
}
