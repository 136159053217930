import {OidcClient} from 'oidc-client';
import {
    ADFS,
    AZURE,
    CONFIDENTIAL,
    CUSTOM,
    generateRandomString,
    OIDC_STATE_PREFIX,
    SIMPLE,
} from './OIDCHelper.js';
import AuthClient from '../client/authClient.js';
import {getCookieValue, removeCookie, saveCookieValue} from '../helpers/cookieHelper';
import {useAuthenticationSettings} from '../../stores/authenticationSettingsStore';
import {useAuthorizationStore} from '../../stores/authorizationStore';

export default class OIDCClient {
    static authenticationSettings;
    /** @typedef {OidcClient} manager */
    static manager;

    static async fetchAuthenticationSettings() {
        await useAuthenticationSettings().ensureAuthenticationSettingsLoaded();
        this.authenticationSettings = useAuthenticationSettings().authenticationSettings;
    }

    static async init() {
        await this.fetchAuthenticationSettings();

        const oidcConfiguration = this.authenticationSettings.openIdConnectConfiguration;

        this.manager = new OidcClient({
            authority: oidcConfiguration.authority,
            client_id: oidcConfiguration.clientId,
            redirect_uri: oidcConfiguration.redirectUri,
            response_type: oidcConfiguration.responseType,
            scope: oidcConfiguration.scopes,
            loadUserInfo: false,
            metadata: {
                token_endpoint: oidcConfiguration.tokenEndpoint,
                issuer: oidcConfiguration.issuer,
                authorization_endpoint: oidcConfiguration.authorizationEndpoint,
                jwks_uri: oidcConfiguration.jwksUri,
                end_session_endpoint: oidcConfiguration.endSessionEndpoint,
            },
        });
    }

    /**
     * Redirects the user to the oidc authorization url
     *
     * @returns {Promise<void>|void}
     */
    static async redirect() {
        await this.fetchAuthenticationSettings();

        const type = this.authenticationSettings.type;

        switch (type) {
            case SIMPLE: {
                OIDCClient.createStateAndNonce();

                window.location.href = `${
                    this.authenticationSettings.openIdConnectConfiguration.authorizationUrl
                }&${new URLSearchParams({
                    state: OIDCClient.oidcState(),
                    nonce: OIDCClient.oidcNonce(),
                })}`;

                break;
            }
            case CUSTOM:
            case AZURE: {
                await this.init();
                const request = await this.manager.createSigninRequest();
                window.location.href = request.url;

                break;
            }
            case ADFS:
            case CONFIDENTIAL: {
                AuthClient.oidc();
                break;
            }
        }
    }

    /**
     * Handles the sign in request for implicit flow
     *
     * @param {string} url The url that comes from the sign in request
     * @return {Promise<string>} The id_token of the sign in request as promise
     */
    static async handleRequest(url) {
        await this.init();

        return this.manager.processSigninResponse(url).then(response => response.id_token);
    }

    static oidcNonce() {
        return (
            getCookieValue(`${OIDC_STATE_PREFIX}_nonce`) ||
            localStorage.getItem(`${OIDC_STATE_PREFIX}_nonce`)
        );
    }

    static getState(prefix) {
        return getCookieValue(`${prefix}_state`);
    }

    static oidcCodeVerifier() {
        return (
            getCookieValue(`${OIDC_STATE_PREFIX}_code_verifier`) ||
            localStorage.getItem(`${OIDC_STATE_PREFIX}_code_verifier`)
        );
    }

    static oidcState() {
        return (
            getCookieValue(`${OIDC_STATE_PREFIX}_state`) ||
            localStorage.getItem(`${OIDC_STATE_PREFIX}_state`)
        );
    }

    static createStateAndNonce() {
        saveCookieValue(`${OIDC_STATE_PREFIX}_state`, generateRandomString(16));
        saveCookieValue(`${OIDC_STATE_PREFIX}_nonce`, generateRandomString(16));
        localStorage.setItem(`${OIDC_STATE_PREFIX}_state`, generateRandomString(16));
        localStorage.setItem(`${OIDC_STATE_PREFIX}_nonce`, generateRandomString(16));
    }
    static safeCodeVerifier(verifier) {
        saveCookieValue(`${OIDC_STATE_PREFIX}_code_verifier`, verifier);
        localStorage.setItem(`${OIDC_STATE_PREFIX}_code_verifier`, verifier);
    }
    static compareState(state, prefix = OIDC_STATE_PREFIX) {
        return (
            (getCookieValue(`${prefix}_state`) || localStorage.getItem(`${prefix}_state`)) === state
        );
    }
    static clearStateAndNonce() {
        removeCookie(`${OIDC_STATE_PREFIX}_state`);
        removeCookie(`${OIDC_STATE_PREFIX}_nonce`);
        removeCookie(`${OIDC_STATE_PREFIX}_code_verifier`);
        localStorage.removeItem(`${OIDC_STATE_PREFIX}_state`);
        localStorage.removeItem(`${OIDC_STATE_PREFIX}_nonce`);
        localStorage.removeItem(`${OIDC_STATE_PREFIX}_code_verifier`);
        useAuthorizationStore().resetUnauthorizedRequestsCount();
    }
    static createState(prefix) {
        saveCookieValue(`${prefix}_state`, generateRandomString(16));
    }
    static clearState(prefix) {
        removeCookie(`${prefix}_state`);
    }
}
