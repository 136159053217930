const TARGET_HOSTS = [
    'https://ms-teams.deskly.live',
    'https://ms-teams-staging.deskly.live',
    'https://guppy-star-genuinely.ngrok-free.app',
];

/**
 * @param {string} message
 */
export function postMessage(message) {
    TARGET_HOSTS.forEach(target => {
        window.parent.postMessage(message, target);
    });
}
