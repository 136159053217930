import API from '../api';
import MeetingRoomView from '../models/view/MeetingRoomView';
import repositoryHelper from '../helpers/RepositoryHelper';
import OccupiedMeetingRoomView from '../models/view/OccupiedMeetingRoomView.js';
import MeetingRoomFilterView from '../models/view/MeetingRoomFilterView';

export default () => ({
    add(meetingRoom, authorizationGroups) {
        const formData = Object.assign({}, meetingRoom, {authorizationGroups});

        return API.postJSON('/api/admin/meeting-room/create', formData)
            .then(API.handle)
            .catch(API.error);
    },

    browseAdmin(roomId) {
        return API.get(`/api/admin/meeting-room/${roomId}/all`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new MeetingRoomView(singleRaw)));
    },

    getNextAvailableMeetingRoomName(roomId) {
        return API.get(`/api/admin/meeting-room/${roomId}/nextAvailableName`)
            .then(API.handle)
            .catch(API.error);
    },

    filter(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/meeting-room/filter',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        return API.get(url)
            .then(API.handle)
            .catch(API.error)
            .then(raw => new MeetingRoomFilterView(raw));
    },
    filterOccupied(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/meeting-room/filter/occupied',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        return API.get(url)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new OccupiedMeetingRoomView(singleRaw)));
    },
    details(meetingRoomId) {
        return API.get(`/api/meeting-room/${meetingRoomId}`)
            .then(API.handle)
            .catch(API.error)
            .then(json => new MeetingRoomView(json));
    },

    detailsAdmin(meetingRoomId) {
        return API.get(`/api/admin/meeting-room/${meetingRoomId}`)
            .then(API.handle)
            .catch(API.error)
            .then(json => new MeetingRoomView(json));
    },

    edit(meetingRoom, authorizationGroups) {
        const formData = Object.assign({}, meetingRoom, {authorizationGroups});

        return API.postJSON(`/api/admin/meeting-room/${meetingRoom.id}/edit`, formData)
            .then(API.handle)
            .catch(API.error);
    },

    delete(meetingRoom) {
        return API.delete(`/api/admin/meeting-room/${meetingRoom.id}`)
            .then(API.handle)
            .catch(API.error);
    },
});
