import CharacteristicView from '../view/CharacteristicView';
import FloorView from '../view/FloorView';
import TimeframeView from './TimeframeView';
import FilterRoomView from './FilterRoomView';

export default class FilterView {
    constructor(json) {
        if (!json) {
            return [];
        }

        const filterTypes = [];

        for (const [key, value] of Object.entries(json)) {
            const filterType = {
                type: key,
                filters: [],
                sort: 0,
                transKey: 'app.dayBooking.filter.' + key,
            };
            switch (key) {
                case 'floors':
                    filterType.sort = 1;
                    value.forEach(filter => {
                        filterType.filters.push(new FloorView(filter));
                    });
                    break;

                case 'rooms':
                    filterType.sort = 1;
                    value.forEach(filter => {
                        filterType.filters.push(new FilterRoomView(filter));
                    });
                    break;

                case 'characteristics':
                    filterType.sort = 0;
                    value.forEach(filter => {
                        filterType.filters.push(new CharacteristicView(filter));
                    });
                    break;

                case 'timeframes':
                    filterType.sort = 0;
                    value.forEach(filter => {
                        filterType.filters.push(new TimeframeView(filter));
                    });
                    break;
            }

            filterTypes.push(filterType);
        }
        return filterTypes;
    }
}
