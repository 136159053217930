import API from '../api';
import FilterView from '../models/view/FilterView';

export default () => ({
    browse(locationId) {
        return API.get('/api/filter/location/' + `${locationId}`)
            .then(API.handle)
            .catch(API.error)
            .then(json => new FilterView(json));
    },
});
