export default {
    root: ({props, context, parent, attrs}) => {
        var _a;
        return {
            class: [
                'h-[38px]',
                // Font
                'leading-none',
                // Flex
                {'flex-1 w-[1%]': parent.instance.$name == 'InputGroup'},
                // Spacing
                {
                    'pl-[38px]': attrs['has-left-icon'],
                    'pr-[38px]': attrs['has-right-icon'],
                },
                'm-0',
                {
                    'py-3 px-3.5': props.size == 'large',
                    'py-1.5 px-2': props.size == 'small',
                    'py-2 px-3': props.size == null,
                },
                // Shape
                {'rounded-md': parent.instance.$name !== 'InputGroup'},
                {
                    'first:rounded-l-md rounded-none last:rounded-r-md':
                        parent.instance.$name == 'InputGroup',
                },
                {'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons},
                // Colors
                'text-surface-800',
                'placeholder:text-surface-400',
                {'bg-surface-0': !context.disabled},
                'border',
                {'border-surface-300 dark:border-surface-700': !props.invalid && !attrs.valid},
                // Invalid State
                'invalid:focus:ring-red-200',
                'invalid:hover:border-red-500',
                {'border-error': props.invalid},
                // Valid State
                {'border-success': attrs.valid},
                // States
                {
                    'hover:border-surface-400': !context.disabled && !props.invalid && !attrs.valid,
                    'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 focus:z-10':
                        !context.disabled,
                    'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default':
                        context.disabled,
                },
                // Filled State *for FloatLabel
                {
                    filled:
                        ((_a = parent.instance) == null ? void 0 : _a.$name) == 'FloatLabel' &&
                        context.filled,
                },
                // Misc
                'appearance-none',
                'transition-colors duration-200',
            ],
        };
    },
};
