export default class UserFeatures {
    constructor(json) {
        this.bookForOthers = !!json['user_book_for_others'];
        this.oidcLogin = !!json['user_oidc_login'];
        this.userManagement = !!json['user_user_management'];
        this.whitelabeling = !!json['user_whitelabeling'];
        this.zoneBookings = !!json['user_zone_bookings'];
        this.meetingRoomBookings = !!json['user_meeting_room_bookings'];
    }
}
