import WeekOverviewUserInformationEntry from './WeekOverviewUserInformationEntry';

export default class WeekOverviewUserFiltering {
    constructor(json) {
        this.id = json.id;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.initials = json.initials;
        this.profileImage = json.profileImage;
        this.informationEntries = [];

        if (json.informationEntries) {
            json.informationEntries.forEach(informationEntry => {
                this.informationEntries.push(
                    new WeekOverviewUserInformationEntry(informationEntry)
                );
            });
        }
    }
}
