import filterHelper from '../helper/filterHelper.js';
import timeHelper from '../../shared/mixins/timeHelper.js';

const SELECT_FROM = 'SELECT_FROM',
    SELECT_UNTIL = 'SELECT_UNTIL';
const state = () => ({
    from: null,
    until: null,
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {string} time
     */
    selectFrom({commit}, time) {
        commit(SELECT_FROM, time);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {string} time
     */
    selectUntil({commit}, time) {
        commit(SELECT_UNTIL, time);
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {function} dispatch
     * @param {object} rootState
     * @param {string} time
     */
    selectUntilWithFetch({commit, dispatch, rootState}, time) {
        const selectedResourceType = rootState.resources.resourceTypes.find(
            ({selected}) => selected
        );

        commit(SELECT_UNTIL, time);

        // only executed after setting the until time because the times are set both at a time
        dispatch(
            filterHelper.methods.getResourceListDispatchString(selectedResourceType.key),
            {},
            {root: true}
        );
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {object} rootState
     */
    defaultSelect({commit, rootState}) {
        const selectedResourceType = rootState.resources.resourceTypes.find(
            ({selected}) => selected
        );

        if (selectedResourceType?.key === 'meetingRoom') {
            commit(
                SELECT_FROM,
                timeHelper.methods.formatApiTime(timeHelper.methods.getRoundedNow())
            );
            commit(
                SELECT_UNTIL,
                timeHelper.methods.formatApiTime(timeHelper.methods.getRoundedAfterOneHour())
            );

            return;
        }

        const allDayTimeframe = rootState.filterTimeframe.availableTimeframes.find(
            timeframe => timeframe.name === 'allDay'
        );

        if (!allDayTimeframe) {
            return;
        }

        commit(SELECT_FROM, allDayTimeframe.from);
        commit(SELECT_UNTIL, allDayTimeframe.until);
    },
};

const mutations = {
    [SELECT_FROM](state, time) {
        state.from = time;
    },
    [SELECT_UNTIL](state, time) {
        state.until = time;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
