import WeekOverviewUserFiltering from './WeekOverviewUserFiltering';

export default class WeekOverviewTeamFiltering {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.departmentName = json.departmentName;
        this.companyName = json.companyName;
        this.limit = json.limit;
        this.offset = json.offset;
        this.visible = json.visible;
        this.users = [];
        this.initialLoading = true;
        this.loadMoreLoading = false;

        if (json.users) {
            json.users.forEach(user => {
                this.users.push(new WeekOverviewUserFiltering(user));
            });
        }
    }

    static createEmpty() {
        return new WeekOverviewTeamFiltering({
            id: '',
            name: '',
            departmentName: '',
            companyName: '',
            limit: WeekOverviewTeamFiltering.defaultPageSize,
            offset: 0,
            users: [],
            visible: true,
            initialLoading: true,
            loadMoreLoading: false,
        });
    }

    static defaultPageSize = 20;
}
