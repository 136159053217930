import {useLocalStorage} from '@vueuse/core';
import {useCookie} from './useCookie';
import {computed} from 'vue';

/**
 * Creates a Ref that is stored inside local storage and cookies.
 * @param key The key that will be used for local storage. For cookies, the prefix 'security.' will be removed.
 * @param initialValue
 * @return {import('vue').WritableComputedRef<any>}
 */
export function useLocalStorageWithCookies(key, initialValue = null) {
    const cookieKey = key.replace('security.', '');

    const storageValue = useLocalStorage(key, initialValue);
    const cookieValue = useCookie(cookieKey);

    return computed({
        get() {
            return cookieValue.value || storageValue.value;
        },
        set(value) {
            storageValue.value = value;
            cookieValue.value = value;
        },
    });
}
