import API from '../api.js';

export default () => ({
    async handleLogin(code, type) {
        return await API.postJSON(
            '/api/sso/handle-login',
            {
                code: code,
                type: type,
            },
            false
        )
            .then(response => {
                const json = response.json();

                if (response.status !== 409) {
                    return json;
                }

                return Promise.reject({
                    status: response.status,
                    statusText: response.statusText,
                    json: json,
                });
            })
            .catch(API.error);
    },
});
